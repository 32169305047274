import React, { useState } from 'react';

import { config } from 'data';
import { formatter } from 'helpers';
import { useLang, useModal, useTable, useTableQuery } from 'hooks';
import { useAllCountriesQuery, useClientQuery, useClientsQuery } from 'hooks/queries';
import { DashboardLayout } from 'layouts';
import { ClientStatus, CountrySelect, TableView } from 'components/layout';
import { Form, Select, Table } from 'components/ui';
import { Uuid } from 'types/common';
import { TableColumns } from 'types/components';
import { Client, ClientStatus as EClientStatus } from 'types/models';

import styles from './styles.module.css';

import Modal from './Modal';

type TableParams = {
  search?: string;
  country?: string;
  status?: EClientStatus;
};

const initialTableParams: TableParams = {
  status: EClientStatus.ACTIVE,
};

const ClientsPage = () => {
  const lang = useLang();
  const modal = useModal();
  const table = useTable<Client, TableParams>([config.CLIENTS_QUERY_KEY], initialTableParams);

  const [clientId, setClientId] = useState<Uuid>();

  const clientQuery = useClientQuery(clientId);
  const countriesQuery = useAllCountriesQuery();

  const clientsQuery = useClientsQuery({
    page: table.page,
    search: table.params.search || undefined,
    country: table.params.country || undefined,
    status: table.params.status || undefined,
  });

  const handleViewClick = (client: Client) => () => {
    setClientId(client.id);

    modal.show();
  };

  useTableQuery(table, clientsQuery);

  const columns: TableColumns<Client> = [
    {
      className: styles.table__email,
      key: 'email',
      title: lang.get('client.list.email'),
      render: (_, client) => <Table.Truncate width="middle">{client.email}</Table.Truncate>,
    }, {
      key: 'firstName',
      title: lang.get('client.list.firstName'),
      render: (_, client) => <Table.Truncate>{client.firstName || '-'}</Table.Truncate>,
    }, {
      key: 'lastName',
      title: lang.get('client.list.lastName'),
      render: (_, client) => <Table.Truncate>{client.lastName || '-'}</Table.Truncate>,
    }, {
      key: 'country',
      title: lang.get('client.list.country'),
      render: (_, client) => client.residenceCountryCode
        ? formatter.formatCountry(countriesQuery.data ?? [], client.residenceCountryCode)
        : '-',
    }, {
      key: 'status',
      title: lang.get('client.list.status'),
      render: (_, client) => <ClientStatus status={client.status} />,
    },
  ];

  return (
    <DashboardLayout title={lang.get('client.list.title')}>
      <TableView title={lang.get('client.list.title')}>

        <TableView.Filters<TableParams>
          initialValues={initialTableParams}
          values={table.params}
          withSearch
          inline
          onSubmit={table.setParams}
        >
          <Form.Item name="country">
            <CountrySelect
              placeholder={lang.get('common.filters.countries')}
              allowClear
              popupMatchSelectWidth={false}
            />
          </Form.Item>
          <Form.Item name="status">
            <Select
              placeholder={lang.get('common.filters.statuses')}
              options={Object.values(EClientStatus).map((status) => ({
                value: status,
                label: lang.get(`client.statuses.${status.toLowerCase()}`),
              }))}
              allowClear
              popupMatchSelectWidth={false}
            />
          </Form.Item>
        </TableView.Filters>

        <TableView.Table<Client>
          table={table}
          columns={columns}
          rowKey={(client) => client.id}
          loading={clientsQuery.isFetching}
          clickable
          onRow={(client) => ({ onClick: handleViewClick(client) })}
        />
      </TableView>

      {clientQuery.data && (
        <Modal
          client={clientQuery.data}
          open={!clientQuery.isFetching && modal.open}
          onClose={modal.hide}
        />
      )}

    </DashboardLayout>
  );
};

export default ClientsPage;
