import React, { FC, useEffect, useState } from 'react';

import { config } from 'data';
import { useLang, useModal, useTable, useTableQuery } from 'hooks';
import { useUserRolesQuery } from 'hooks/queries';
import { Plus } from 'components/icons';
import { TableView } from 'components/layout';
import { Button, Modal, Table } from 'components/ui';
import { ModalBaseProps, TableColumns } from 'types/components';
import { UserRole } from 'types/models';

import styles from './styles.module.css';

import SingleModal from './SingleModal';

const RolesModal: FC<ModalBaseProps> = ({ open, onClose }) => {
  const lang = useLang();
  const modal = useModal();
  const table = useTable<UserRole>([config.USER_ROLES_QUERY_KEY]);

  const [role, setRole] = useState<UserRole>();

  const rolesQuery = useUserRolesQuery({ page: table.page });

  const handleCreateClick = () => {
    setRole(undefined);

    modal.show();
  };

  const handleViewClick = (role: UserRole) => () => {
    setRole(role);

    modal.show();
  };

  useTableQuery(table, rolesQuery);

  useEffect(() => {
    if (!open) {
      modal.hide();
    }
  }, [open, modal]);

  const columns: TableColumns<UserRole> = [
    {
      className: styles.table__name,
      key: 'name',
      title: lang.get('role.list.name'),
      render: (_, role) => <Table.Truncate>{role.name}</Table.Truncate>,
    },
  ];

  return (
    <Modal
      cancelText={lang.get('common.actions.close')}
      okButtonProps={{ hidden: true }}
      open={open}
      onCancel={onClose}
    >
      <TableView
        title={lang.get('role.list.title')}
        caption={lang.choice('role.list.caption', rolesQuery.data?.total ?? 0)}
        actions={(
          <Button icon={<Plus />} onClick={handleCreateClick}>
            {lang.get('role.list.create')}
          </Button>
        )}
      >

        <TableView.Table<UserRole>
          table={table}
          columns={columns}
          rowKey={(role) => role.id}
          loading={rolesQuery.isFetching}
          clickable
          onRow={(role) => ({ onClick: handleViewClick(role) })}
        />

        <SingleModal
          role={role}
          open={modal.open}
          onClose={modal.hide}
        />

      </TableView>
    </Modal>
  );
};

export default RolesModal;
