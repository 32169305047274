import React, { FC } from 'react';

import { config } from 'data';
import { formatter } from 'helpers';
import { clientService } from 'services';
import { useLang, useMutation, useQueryInvalidate } from 'hooks';
import { useAllCountriesQuery } from 'hooks/queries';
import { ClientStatus, VerificationDescriptions } from 'components/layout';
import { Descriptions, Form, Link, Modal, PopconfirmButton } from 'components/ui';
import { ModalBaseProps } from 'types/components';
import { Client, ClientStatus as EClientStatus } from 'types/models';

type ClientModalProps = ModalBaseProps & {
  client: Client;
};

const ClientModal: FC<ClientModalProps> = ({
  client,
  open,
  onClose,
}) => {
  const lang = useLang();
  const queryInvalidate = useQueryInvalidate();

  const countriesQuery = useAllCountriesQuery();

  const invalidateClientQueries = async () => {
    await queryInvalidate([config.CLIENTS_QUERY_KEY]);
    await queryInvalidate([config.CLIENT_QUERY_KEY, client.id]);
  };

  const verifyClientMutation = useMutation({
    mutationFn: () => clientService.verifyClient(client.id),
    onSuccess: invalidateClientQueries,
    successNotification: lang.get('client.modal.verifySuccess', { email: client.email }),
  });

  const unverifyClientMutation = useMutation({
    mutationFn: () => clientService.unverifyClient(client.id),
    onSuccess: invalidateClientQueries,
    successNotification: lang.get('client.modal.unverifySuccess', { email: client.email }),
  });

  const activateClientMutation = useMutation({
    mutationFn: () => clientService.activateClient(client.id),
    onSuccess: invalidateClientQueries,
    successNotification: lang.get('client.modal.activateSuccess', { email: client.email }),
  });

  const deactivateClientMutation = useMutation({
    mutationFn: () => clientService.deactivateClient(client.id),
    onSuccess: invalidateClientQueries,
    successNotification: lang.get('client.modal.deactivateSuccess', { email: client.email }),
  });

  const handleVerify = async () => {
    await verifyClientMutation.mutateAsync();

    onClose();
  };

  const handleUnverify = async () => {
    await unverifyClientMutation.mutateAsync();

    onClose();
  };

  const handleActivate = async () => {
    await activateClientMutation.mutateAsync();

    onClose();
  };

  const handleDeactivate = async () => {
    await deactivateClientMutation.mutateAsync();

    onClose();
  };

  return (
    <Modal
      title={lang.get('client.modal.viewTitle', { email: client.email })}
      caption={lang.get('client.modal.viewCaption')}
      cancelText={lang.get('common.actions.close')}
      okButtonProps={{ hidden: true }}
      open={open}
      onCancel={onClose}
    >

      <Form.ActionsItem>

        {client.verification?.verified ? (
          <PopconfirmButton
            title={lang.get('client.modal.unverifyTitle')}
            danger
            loading={unverifyClientMutation.isPending}
            onConfirm={handleUnverify}
          >
            {lang.get('common.actions.unverify')}
          </PopconfirmButton>
        ) : (
          <PopconfirmButton
            title={lang.get('client.modal.verifyTitle')}
            type="primary"
            ghost
            loading={verifyClientMutation.isPending}
            onConfirm={handleVerify}
          >
            {lang.get('common.actions.verify')}
          </PopconfirmButton>
        )}

        {client.status === EClientStatus.ACTIVE ? (
          <PopconfirmButton
            title={lang.get('client.modal.deactivateTitle')}
            danger
            loading={deactivateClientMutation.isPending}
            onConfirm={handleDeactivate}
          >
            {lang.get('common.actions.deactivate')}
          </PopconfirmButton>
        ) : (
          <PopconfirmButton
            title={lang.get('client.modal.activateTitle')}
            type="primary"
            ghost
            loading={activateClientMutation.isPending}
            onConfirm={handleActivate}
          >
            {lang.get('common.actions.activate')}
          </PopconfirmButton>
        )}

      </Form.ActionsItem>

      <Form.Divider />

      <Descriptions
        items={[{
          label: lang.get('common.form.firstName.label'),
          children: client.firstName ?? '-',
        }, {
          label: lang.get('common.form.lastName.label'),
          children: client.lastName ?? '-',
        }, {
          label: lang.get('common.form.email.label'),
          children: <Link href={`mailto:${client.email}`}>{client.email}</Link>,
        }, {
          label: lang.get('common.form.phone.label'),
          children: client.phone
            ? <Link href={`tel:${client.phone}`}>{client.phone}</Link>
            : '-',
        }, {
          label: lang.get('common.form.country.label'),
          children: client.residenceCountryCode
            ? formatter.formatCountry(countriesQuery.data ?? [], client.residenceCountryCode)
            : '-',
        }, {
          label: lang.get('common.form.status.label'),
          children: <ClientStatus status={client.status} />,
        }]}
      />

      <Form.Divider />

      <VerificationDescriptions verification={client.verification} />

    </Modal>
  );
};

export default ClientModal;
