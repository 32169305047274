import React, { useState } from 'react';
import truncate from 'lodash/truncate';

import { config, moment } from 'data';
import { formatter } from 'helpers';
import { useLang, useModal, useTable, useTableQuery } from 'hooks';
import { useBundleQuery, useBundlesQuery } from 'hooks/queries';
import { DashboardLayout } from 'layouts';
import { Upload } from 'components/icons';
import { BundleStatus, BusinessAccountIcon, TableView } from 'components/layout';
import { Button, Checkbox, DateRangePicker, Form, Space, Table } from 'components/ui';
import { Nullable, Uuid } from 'types/common';
import { TableColumns } from 'types/components';
import { Bundle, BundleStatus as EBundleStatus } from 'types/models';

import styles from './styles.module.css';

import CreateModal from './CreateModal';
import ViewModal from './ViewModal';

type TableParams = {
  search?: string;
  status?: EBundleStatus[];
  dates?: Nullable<[Nullable<string>, Nullable<string>]>;
};

const initialTableParams: TableParams = {
  //
};

const BundlesPage = () => {
  const lang = useLang();
  const createModal = useModal();
  const viewModal = useModal();
  const table = useTable<Bundle, TableParams>([config.BUNDLES_QUERY_KEY], initialTableParams);

  const [bundleId, setBundleId] = useState<Uuid>();

  const bundleQuery = useBundleQuery(bundleId);

  const bundlesQuery = useBundlesQuery({
    page: table.page,
    search: table.params.search || undefined,
    status: table.params.status || undefined,
    dateFrom: (table.params.dates && table.params.dates[0]) || undefined,
    dateTo: (table.params.dates && table.params.dates[1]) || undefined,
  });

  const handleViewClick = (bundle: Bundle) => () => {
    setBundleId(bundle.id);

    viewModal.show();
  };

  useTableQuery(table, bundlesQuery);

  const columns: TableColumns<Bundle> = [
    {
      className: styles.table__date,
      key: 'date',
      title: lang.get('bundle.list.date'),
      render: (_, bundle) => formatter.formatDateTime(bundle.updatedAt ?? bundle.createdAt),
    }, {
      className: styles.table__id,
      key: 'id',
      title: lang.get('bundle.list.id'),
      render: (_, bundle) => (
        <span title={bundle.id}>
          {truncate(bundle.id, { length: config.TABLE_ID_MAX_LENGTH })}
        </span>
      ),
    }, {
      key: 'businessAccount',
      title: lang.get('bundle.list.businessAccount'),
      render: (_, bundle) => (
        <Table.Truncate title={bundle.client.name} width="middle">
          <BusinessAccountIcon businessAccountType={bundle.client.type} />
          <span>{bundle.client.name}</span>
        </Table.Truncate>
      ),
    }, {
      key: 'status',
      title: lang.get('bundle.list.status'),
      render: (_, bundle) => <BundleStatus status={bundle.status} />,
    },
  ];

  return (
    <DashboardLayout title={lang.get('bundle.list.title')}>
      <TableView
        title={lang.get('bundle.list.title')}
        actions={(
          <Button icon={<Upload />} onClick={createModal.show}>
            {lang.get('common.actions.upload')}
          </Button>
        )}
      >

        <TableView.Filters<TableParams>
          initialValues={initialTableParams}
          values={table.params}
          withSearch
          onSubmit={table.setParams}
        >
          <Form.Item name="status" label={lang.get('common.filters.status')}>
            <Checkbox.Group>
              <Space direction="vertical">
                {Object.values(EBundleStatus).map((status) => (
                  <Checkbox key={status} value={status}>
                    {lang.get(`bundle.statuses.${status.toLowerCase()}`)}
                  </Checkbox>
                ))}
              </Space>
            </Checkbox.Group>
          </Form.Item>
          <Form.Item name="dates" label={lang.get('common.filters.date')}>
            <DateRangePicker maxDate={moment()} allowEmpty />
          </Form.Item>
        </TableView.Filters>

        <TableView.Table<Bundle>
          table={table}
          columns={columns}
          rowKey={(bundle) => bundle.id}
          loading={bundlesQuery.isFetching}
          clickable
          onRow={(bundle) => ({ onClick: handleViewClick(bundle) })}
        />
      </TableView>

      <CreateModal
        open={createModal.open}
        onClose={createModal.hide}
      />

      {bundleQuery.data && (
        <ViewModal
          bundle={bundleQuery.data}
          open={!bundleQuery.isFetching && viewModal.open}
          onClose={viewModal.hide}
        />
      )}

    </DashboardLayout>
  );
};

export default BundlesPage;
