import React from 'react';
import { Link } from 'react-router-dom';

import { url } from 'helpers';
import { authService } from 'services';
import { useLang, useModal, useMutation } from 'hooks';
import { ChevronDown, Logo, Logout, Setting } from 'components/icons';
import { ProfileModal } from 'components/layout';
import { Button, Container, Flex, Layout, Menu } from 'components/ui';

import styles from './styles.module.css';

const Header = () => {
  const lang = useLang();
  const profileModal = useModal();

  const logoutMutation = useMutation({
    mutationFn: authService.logout,
  });

  return (
    <Layout.Header className={styles.header}>
      <Container className={styles.header__container}>

        <Link className={styles.header__logo} to={url.toDashboard()}>
          <Logo />
        </Link>

        <Menu
          rootClassName={styles.header__menu}
          items={[{
            key: 'clients',
            label: <Link to={url.toClients()}>{lang.get('common.menu.clients')}</Link>,
          }, {
            key: 'businessAccountsMenu',
            label: <>{lang.get('common.menu.businessAccounts')} <ChevronDown /></>,
            children: [{
              key: 'businessAccounts',
              label: <Link to={url.toBusinessAccounts()}>{lang.get('common.menu.businessAccounts')}</Link>,
            }, {
              key: 'businessApplications',
              label: <Link to={url.toBusinessApplications()}>{lang.get('common.menu.businessApplications')}</Link>,
            }],
          }, {
            key: 'transactionsMenu',
            label: <>{lang.get('common.menu.transactions')} <ChevronDown /></>,
            children: [{
              key: 'transactions',
              label: <Link to={url.toTransactions()}>{lang.get('common.menu.transactions')}</Link>,
            }, {
              key: 'bundles',
              label: <Link to={url.toBundles()}>{lang.get('common.menu.bundles')}</Link>,
            }],
          }, {
            key: 'reportsMenu',
            label: <>{lang.get('common.menu.reports')} <ChevronDown /></>,
            children: [{
              key: 'dailyRemittance',
              label: (
                <Link to={url.toDailyRemittance()}>
                  {lang.get('common.menu.dailyRemittance')}
                </Link>
              ),
            }, {
              key: 'businessAccountsDailyBalance',
              label: (
                <Link to={url.toBusinessAccountsDailyBalance()}>
                  {lang.get('common.menu.businessAccountsDailyBalance')}
                </Link>
              ),
            }, {
              key: 'businessAccountsDailyRevenue',
              label: (
                <Link to={url.toBusinessAccountsDailyRevenue()}>
                  {lang.get('common.menu.businessAccountsDailyRevenue')}
                </Link>
              ),
            }, {
              key: 'providersDailyBalance',
              label: (
                <Link to={url.toProvidersDailyBalance()}>
                  {lang.get('common.menu.providersDailyBalance')}
                </Link>
              ),
            }, {
              key: 'providersDailySettlement',
              label: (
                <Link to={url.toProvidersDailySettlement()}>
                  {lang.get('common.menu.providersDailySettlement')}
                </Link>
              ),
            }],
          }, {
            key: 'settingsMenu',
            label: <>{lang.get('common.menu.settings')} <ChevronDown /></>,
            children: [{
              key: 'auditLogs',
              label: <Link to={url.toAuditLogs()}>{lang.get('common.menu.auditLogs')}</Link>,
            }, {
              key: 'costManagement',
              label: <Link to={url.toCostManagement()}>{lang.get('common.menu.costManagement')}</Link>,
            }, {
              key: 'currencies',
              label: <Link to={url.toCurrencies()}>{lang.get('common.menu.currencies')}</Link>,
            }, {
              key: 'feeManagement',
              label: <Link to={url.toFeeManagement()}>{lang.get('common.menu.feeManagement')}</Link>,
            }, {
              key: 'users',
              label: <Link to={url.toUsers()}>{lang.get('common.menu.users')}</Link>,
            }],
          }]}
          mode="horizontal"
        />

        <Flex className={styles.header__aside} gap="small">
          <Button
            className={styles.header__aside__button}
            title={lang.get('common.menu.profile')}
            icon={<Setting />}
            color="primary"
            variant="filled"
            onClick={profileModal.show}
          />
          <Button
            className={styles.header__aside__button}
            title={lang.get('common.menu.logout')}
            icon={<Logout />}
            color="primary"
            variant="outlined"
            loading={logoutMutation.isPending}
            onClick={() => logoutMutation.mutateAsync()}
          />
        </Flex>

        <ProfileModal
          open={profileModal.open}
          onClose={profileModal.hide}
        />

      </Container>
    </Layout.Header>
  );
};

export default Header;
