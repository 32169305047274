import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { config, moment } from 'data';
import { formatter, url } from 'helpers';
import { reportService, transactionService } from 'services';
import { fetchPaginatedResponseFully } from 'services/helpers';
import { useLang, useQuery, useTable, useTableQuery } from 'hooks';
import { TableView } from 'components/layout';
import { DateRangePicker, Form } from 'components/ui';
import { ExportColumns, TableColumns, TableViewProps } from 'types/components';
import { BusinessAccount, ReportDailyRemittanceItem } from 'types/models';
import { ReportDailyRemittanceParams } from 'types/services';
import { TransactionsViewFilters } from 'types/views';

import styles from './styles.module.css';

const EXPORT_FILE_NAME = 'report-daily-remittance';

type TableParams = {
  dates: [string, string];
};

const initialTableParams: TableParams = {
  dates: [
    moment().subtract(1, 'day').startOf('day').toISOString(),
    moment().subtract(1, 'day').endOf('day').toISOString(),
  ],
};

type DailyRemittanceViewProps = Pick<TableViewProps, 'icon' | 'title' | 'caption'> & {
  businessAccount?: BusinessAccount;
};

const DailyRemittanceView: FC<DailyRemittanceViewProps> = ({ businessAccount, ...props }) => {
  const lang = useLang();
  const table = useTable<ReportDailyRemittanceItem, TableParams>([config.DAILY_REMITTANCE_QUERY_KEY, businessAccount?.id], initialTableParams);

  const reportParams: ReportDailyRemittanceParams = {
    clientIds: businessAccount && [businessAccount.id],
    page: table.page,
    dateFrom: table.params.dates[0],
    dateTo: table.params.dates[1],
  };

  const reportQuery = useQuery({
    queryKey: [config.DAILY_REMITTANCE_QUERY_KEY, reportParams],
    queryFn: () => reportService.getDailyRemittance(reportParams),
  });

  useTableQuery(table, reportQuery);

  const formatItemDate = (item: ReportDailyRemittanceItem) => formatter.formatDate(item.date);

  const formatItemBundlesUploaded = (item: ReportDailyRemittanceItem) => formatter.formatNumber(item.bundles, 0);

  const formatItemTransactionsAccepted = (item: ReportDailyRemittanceItem) => formatter.formatNumber(item.acceptedTransactions, 0);

  const formatItemTransactionsRejected = (item: ReportDailyRemittanceItem) => formatter.formatNumber(item.rejectedTransactions, 0);

  const formatItemCurrency = (item: ReportDailyRemittanceItem) => item.currency;

  const formatItemAmountRemitted = (item: ReportDailyRemittanceItem) => formatter.formatNumber(item.remittedAmount);

  const formatItemAmountRejected = (item: ReportDailyRemittanceItem) => formatter.formatNumber(item.rejectedAmount);

  const formatItemTransactionFeesRemitted = (item: ReportDailyRemittanceItem) => formatter.formatNumber(item.remittedTransactionFees);

  const formatItemFxFeesRemitted = (item: ReportDailyRemittanceItem) => formatter.formatNumber(item.remittedFxFees);

  const exportColumns: ExportColumns<ReportDailyRemittanceItem> = useMemo(() => [
    {
      title: lang.get('report.dailyRemittance.date'),
      render: (item) => formatItemDate(item),
    }, {
      title: lang.get('report.dailyRemittance.bundlesUploaded'),
      render: (item) => formatItemBundlesUploaded(item),
    }, {
      title: lang.get('report.dailyRemittance.transactionsAccepted'),
      render: (item) => formatItemTransactionsAccepted(item),
    }, {
      title: lang.get('report.dailyRemittance.transactionsRejected'),
      render: (item) => formatItemTransactionsRejected(item),
    }, {
      title: lang.get('report.dailyRemittance.currency'),
      render: (item) => formatItemCurrency(item),
    }, {
      title: lang.get('report.dailyRemittance.amountRemitted'),
      render: (item) => formatItemAmountRemitted(item),
    }, {
      title: lang.get('report.dailyRemittance.amountRejected'),
      render: (item) => formatItemAmountRejected(item),
    }, {
      title: lang.get('report.dailyRemittance.transactionFeesRemitted'),
      render: (item) => formatItemTransactionFeesRemitted(item),
    }, {
      title: lang.get('report.dailyRemittance.fxFeesRemitted'),
      render: (item) => formatItemFxFeesRemitted(item),
    },
  ], [lang]);

  const tableColumns: TableColumns<ReportDailyRemittanceItem> = [
    {
      className: styles.table__date,
      key: 'date',
      title: lang.get('report.dailyRemittance.date'),
      render: (_, item) => formatItemDate(item),
    }, {
      key: 'bundlesUploaded',
      title: lang.get('report.dailyRemittance.bundlesUploaded'),
      render: (_, item) => formatItemBundlesUploaded(item),
    }, {
      key: 'transactionsAccepted',
      title: lang.get('report.dailyRemittance.transactionsAccepted'),
      render: (_, item) => {
        const filters: TransactionsViewFilters = {
          search: businessAccount?.name,
          originCurrency: item.currency,
          status: transactionService.getTransactionAcceptedStatuses(),
          dates: [
            moment(item.date).startOf('day').toISOString(),
            moment(item.date).endOf('day').toISOString(),
          ],
        };

        return (
          <Link to={url.toTransactions(undefined, { [config.FILTERS_QUERY_PARAM]: filters })}>
            {formatItemTransactionsAccepted(item)}
          </Link>
        );
      },
    }, {
      key: 'transactionsRejected',
      title: lang.get('report.dailyRemittance.transactionsRejected'),
      render: (_, item) => {
        const filters: TransactionsViewFilters = {
          search: businessAccount?.name,
          originCurrency: item.currency,
          status: transactionService.getTransactionRejectedStatuses(),
          dates: [
            moment(item.date).startOf('day').toISOString(),
            moment(item.date).endOf('day').toISOString(),
          ],
        };

        return (
          <Link to={url.toTransactions(undefined, { [config.FILTERS_QUERY_PARAM]: filters })}>
            {formatItemTransactionsRejected(item)}
          </Link>
        );
      },
    }, {
      key: 'currency',
      title: lang.get('report.dailyRemittance.currency'),
      render: (_, item) => formatItemCurrency(item),
    }, {
      className: styles.table__amount,
      key: 'amountRemitted',
      title: lang.get('report.dailyRemittance.amountRemitted'),
      render: (_, item) => formatItemAmountRemitted(item),
    }, {
      className: styles.table__balance,
      key: 'amountRejected',
      title: lang.get('report.dailyRemittance.amountRejected'),
      render: (_, item) => formatItemAmountRejected(item),
    }, {
      className: styles.table__balance,
      key: 'transactionFeesRemitted',
      title: lang.get('report.dailyRemittance.transactionFeesRemitted'),
      render: (_, item) => formatItemTransactionFeesRemitted(item),
    }, {
      className: styles.table__balance,
      key: 'fxFeesRemitted',
      title: lang.get('report.dailyRemittance.fxFeesRemitted'),
      render: (_, item) => formatItemFxFeesRemitted(item),
    },
  ];

  return (
    <TableView
      actions={(
        <TableView.ExportButton<ReportDailyRemittanceItem>
          table={table}
          fileName={[
            EXPORT_FILE_NAME,
            businessAccount?.name,
            moment(table.params.dates[0]).format(config.DATE_RAW_FORMAT),
            moment(table.params.dates[1]).format(config.DATE_RAW_FORMAT),
          ].filter(Boolean).join('-')}
          columns={exportColumns}
          fetchData={() => fetchPaginatedResponseFully(reportService.getDailyRemittance, reportParams)}
        />
      )}
      {...props}
    >

      <TableView.Filters<TableParams>
        initialValues={initialTableParams}
        values={table.params}
        inline
        onSubmit={table.setParams}
      >
        <Form.Item name="dates">
          <DateRangePicker maxDate={moment().subtract(1, 'day').endOf('day')} allowClear={false} />
        </Form.Item>
      </TableView.Filters>

      <TableView.Table<ReportDailyRemittanceItem>
        table={table}
        columns={tableColumns}
        rowKey={(item) => item.date + item.currency}
        loading={reportQuery.isFetching}
      />

    </TableView>
  );
};

export default DailyRemittanceView;
