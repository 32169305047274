import React, { FC, useEffect } from 'react';

import { config, yup } from 'data';
import { userService } from 'services';
import { useForm, useLang, useMutation, useQueryInvalidate } from 'hooks';
import { Trash } from 'components/icons';
import { Form, Input, Modal, PopconfirmButton, Select, TextArea } from 'components/ui';
import { ModalBaseProps } from 'types/components';
import { UserRole, UserRolePermission } from 'types/models';
import { UserRoleParams } from 'types/services';

type FormValues = UserRoleParams;

const initialValues: FormValues = {
  name: '',
  permissions: [],
};

const permissions = Object.values(UserRolePermission);

const validationSchema = yup.object({
  name: yup.string().required().trim().max(config.STRING_MAX_LENGTH),
  description: yup.string().notRequired().trim().max(config.TEXT_MAX_LENGTH),
  permissions: yup.array().required().of(
    yup.string().required().oneOf(permissions),
  ),
});

type RoleModalProps = ModalBaseProps & {
  role?: UserRole;
};

const RoleModal: FC<RoleModalProps> = ({
  role,
  open,
  onClose,
}) => {
  const form = useForm<FormValues>();
  const lang = useLang();
  const queryInvalidate = useQueryInvalidate();

  const roleId = role?.id ?? '';
  const roleName = role?.name ?? '';

  const invalidateRoleQueries = async () => {
    await queryInvalidate([config.USERS_QUERY_KEY]);
    await queryInvalidate([config.USER_ROLES_QUERY_KEY]);
  };

  const createRoleMutation = useMutation({
    mutationFn: userService.createRole,
    onSuccess: invalidateRoleQueries,
    successNotification: lang.get('role.modal.createSuccess'),
  });

  const updateRoleMutation = useMutation({
    mutationFn: (values: FormValues) => userService.updateRole(roleId, values),
    onSuccess: invalidateRoleQueries,
    successNotification: lang.get('role.modal.updateSuccess', { name: roleName }),
  });

  const deleteRoleMutation = useMutation({
    mutationFn: () => userService.deleteRole(roleId),
    onSuccess: invalidateRoleQueries,
    successNotification: lang.get('role.modal.deleteSuccess', { name: roleName }),
  });

  const handleSubmit = async (values: FormValues) => {
    role
      ? await updateRoleMutation.mutateAsync(values)
      : await createRoleMutation.mutateAsync(values);

    onClose();
  };

  const handleDelete = async () => {
    await deleteRoleMutation.mutateAsync();

    onClose();
  };

  useEffect(() => {
    if (open && role) {
      form.setFieldsValue(role);
    }
  }, [role, open, form]);

  const isEditing = Boolean(role);
  const isAssigned = Boolean(role?.assigned);

  return (
    <Modal
      title={
        isEditing
          ? lang.get('role.modal.updateTitle', { name: roleName })
          : lang.get('role.modal.createTitle')
      }
      caption={
        isEditing
          ? lang.get('role.modal.updateCaption')
          : lang.get('role.modal.createCaption')
      }
      okText={isEditing ? lang.get('common.actions.save') : lang.get('common.actions.create')}
      extraActions={isEditing && !isAssigned && (
        <PopconfirmButton
          title={lang.get('role.modal.deleteTitle')}
          icon={<Trash />}
          danger
          loading={deleteRoleMutation.isPending}
          onConfirm={handleDelete}
        >
          {lang.get('common.actions.delete')}
        </PopconfirmButton>
      )}
      width="small"
      open={open}
      confirmLoading={createRoleMutation.isPending || updateRoleMutation.isPending}
      onOk={form.submit}
      onCancel={onClose}
    >
      <Form
        form={form}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onFinish={handleSubmit}
      >
        <Form.Item name="name" label={lang.get('common.form.name.label')}>
          <Input placeholder={lang.get('common.form.name.placeholder')} />
        </Form.Item>
        <Form.Item name="description" label={lang.get('common.form.description.label')}>
          <TextArea placeholder={lang.get('common.form.description.placeholder')} />
        </Form.Item>
        <Form.Item name="permissions" label={lang.get('role.modal.permissions.label')}>
          <Select
            placeholder={lang.get('role.modal.permissions.placeholder')}
            options={permissions.map((permission) => ({
              value: permission,
              label: lang.get(`role.permissions.${permission.toLowerCase()}`),
            }))}
            mode="multiple"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default RoleModal;
