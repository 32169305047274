import React, { FC } from 'react';

import { config } from 'data';
import { businessApplicationService } from 'services';
import { useLang, useModal, useMutation, useQueryInvalidate } from 'hooks';
import { Button, Form, PopconfirmButton } from 'components/ui';
import { BusinessApplication, BusinessApplicationStatus as EBusinessApplicationStatus } from 'types/models';
import { BusinessApplicationRejectParams, BusinessApplicationVerifyParams } from 'types/services';

import DetailsModal from './DetailsModal';
import RejectModal from './RejectModal';
import VerifyModal from './VerifyModal';

enum BusinessApplicationAction {
  APPROVE = 'approve',
  REJECT = 'reject',
  VERIFY = 'verify',
  UNVERIFY = 'unverify',
}

const statusActions: Record<EBusinessApplicationStatus, BusinessApplicationAction[]> = {
  [EBusinessApplicationStatus.APPROVED]: [BusinessApplicationAction.VERIFY, BusinessApplicationAction.UNVERIFY],
  [EBusinessApplicationStatus.ARCHIVED]: [],
  [EBusinessApplicationStatus.DECLINED]: [BusinessApplicationAction.APPROVE],
  [EBusinessApplicationStatus.FAILED]: [BusinessApplicationAction.VERIFY],
  [EBusinessApplicationStatus.IN_PROGRESS]: [BusinessApplicationAction.VERIFY, BusinessApplicationAction.UNVERIFY],
  [EBusinessApplicationStatus.NEW]: [BusinessApplicationAction.APPROVE, BusinessApplicationAction.REJECT],
  [EBusinessApplicationStatus.PASSED]: [],
  [EBusinessApplicationStatus.REVIEW]: [BusinessApplicationAction.VERIFY, BusinessApplicationAction.UNVERIFY],
};

type BusinessApplicationActionsProps = {
  businessApplication: BusinessApplication;
  onSubmit: VoidFunction;
};

const BusinessApplicationActions: FC<BusinessApplicationActionsProps> = ({ businessApplication, onSubmit }) => {
  const lang = useLang();
  const detailsModal = useModal();
  const rejectModal = useModal();
  const verifyModal = useModal();
  const unverifyModal = useModal();
  const queryInvalidate = useQueryInvalidate();

  const invalidateBusinessApplicationQueries = async () => {
    await queryInvalidate([config.BUSINESS_APPLICATIONS_QUERY_KEY]);
  };

  const approveBusinessApplicationMutation = useMutation({
    mutationFn: () => businessApplicationService.approveBusinessApplication(businessApplication.id),
    onSuccess: invalidateBusinessApplicationQueries,
    successNotification: lang.get('businessApplication.modal.approveSuccess', { name: businessApplication.name }),
  });

  const rejectBusinessApplicationMutation = useMutation({
    mutationFn: (values: BusinessApplicationRejectParams) => businessApplicationService.rejectBusinessApplication(businessApplication.id, values),
    onSuccess: invalidateBusinessApplicationQueries,
    successNotification: lang.get('businessApplication.modal.rejectSuccess', { name: businessApplication.name }),
  });

  const verifyBusinessApplicationMutation = useMutation({
    mutationFn: (values: BusinessApplicationVerifyParams) => businessApplicationService.verifyBusinessApplication(businessApplication.kybDetails?.id ?? '', values),
    onSuccess: invalidateBusinessApplicationQueries,
    successNotification: lang.get('businessApplication.modal.verifySuccess', { name: businessApplication.name }),
  });

  const unverifyBusinessApplicationMutation = useMutation({
    mutationFn: (values: BusinessApplicationRejectParams) => businessApplicationService.unverifyBusinessApplication(businessApplication.kybDetails?.id ?? '', values),
    onSuccess: invalidateBusinessApplicationQueries,
    successNotification: lang.get('businessApplication.modal.unverifySuccess', { name: businessApplication.name }),
  });

  const handleApprove = async () => {
    await approveBusinessApplicationMutation.mutateAsync();

    onSubmit();
  };

  const handleReject = async (values: BusinessApplicationRejectParams) => {
    await rejectBusinessApplicationMutation.mutateAsync(values);

    onSubmit();
  };

  const handleVerify = async (values: BusinessApplicationVerifyParams) => {
    await verifyBusinessApplicationMutation.mutateAsync(values);

    onSubmit();
  };

  const handleUnverify = async (values: BusinessApplicationRejectParams) => {
    await unverifyBusinessApplicationMutation.mutateAsync(values);

    onSubmit();
  };

  const actions = statusActions[businessApplication.status];
  const hasActions = Boolean(actions.length);

  return (
    <>

      <Form.ActionsItem hidden={!hasActions}>

        {actions.includes(BusinessApplicationAction.APPROVE) && (
          <PopconfirmButton
            title={lang.get('businessApplication.modal.approveTitle')}
            type="primary"
            ghost
            loading={approveBusinessApplicationMutation.isPending}
            onConfirm={handleApprove}
          >
            {lang.get('common.actions.approve')}
          </PopconfirmButton>
        )}

        {actions.includes(BusinessApplicationAction.REJECT) && (
          <Button type="default" danger onClick={rejectModal.show}>
            {lang.get('common.actions.reject')}
          </Button>
        )}

        {actions.includes(BusinessApplicationAction.VERIFY) && (
          <Button type="primary" ghost onClick={verifyModal.show}>
            {lang.get('common.actions.verify')}
          </Button>
        )}

        {actions.includes(BusinessApplicationAction.UNVERIFY) && (
          <Button type="default" danger onClick={unverifyModal.show}>
            {lang.get('common.actions.unverify')}
          </Button>
        )}

      </Form.ActionsItem>

      <Form.ActionsItem label={lang.get('businessApplication.modal.details')}>
        <Button type="default" onClick={detailsModal.show}>
          {lang.get('common.actions.manage')}
        </Button>
      </Form.ActionsItem>

      <Form.Divider />

      <DetailsModal
        businessApplication={businessApplication}
        open={detailsModal.open}
        onClose={detailsModal.hide}
      />

      <RejectModal
        businessApplication={businessApplication}
        open={rejectModal.open}
        loading={rejectBusinessApplicationMutation.isPending}
        onClose={rejectModal.hide}
        onSubmit={handleReject}
      />

      <VerifyModal
        businessApplication={businessApplication}
        open={verifyModal.open}
        loading={verifyBusinessApplicationMutation.isPending}
        onClose={verifyModal.hide}
        onSubmit={handleVerify}
      />

      <RejectModal
        businessApplication={businessApplication}
        open={unverifyModal.open}
        loading={unverifyBusinessApplicationMutation.isPending}
        onClose={unverifyModal.hide}
        onSubmit={handleUnverify}
      />

    </>
  );
};

export default BusinessApplicationActions;
