import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import debounce from 'lodash/debounce';

import { config } from 'data';
import { url } from 'helpers';
import { useForm, useLang } from 'hooks';
import { Filter } from 'components/icons';
import { Button, Drawer, Flex, Form } from 'components/ui';
import { FormProps } from 'types/components';

import styles from './styles.module.css';

import Search from './Search';

type FiltersProps<FormValues> = {
  form?: FormProps<FormValues>['form'];
  initialValues?: Partial<FormValues>;
  values?: FormValues;
  children?: ReactNode;
  searchName?: string;
  withSearch?: boolean;
  inline?: boolean;
  onSubmit: (values: FormValues) => void;
};

const Filters = <FormValues extends Record<string, unknown>>({
  form,
  initialValues,
  values,
  children,
  searchName = 'search',
  withSearch,
  inline,
  onSubmit,
}: FiltersProps<FormValues>) => {
  const formInstance = useForm<FormValues>(form);
  const lang = useLang();
  const [searchParams, setSearchParams] = useSearchParams();

  const [opened, setOpened] = useState(false);

  const open = () => setOpened(true);

  const close = () => setOpened(false);

  const getFormValues = useCallback(() => {
    const values = formInstance.prepareValues(
      formInstance.getFieldsValue(true),
    );

    const activeValues = Object.entries(values).filter(([, value]) => {
      if (Array.isArray(value)) {
        return value.length > 0;
      }

      return Boolean(value);
    });

    return Object.fromEntries(activeValues) as FormValues;
  }, [formInstance]);

  const handleCleanAllClick = () => {
    formInstance.reset(initialValues);
    formInstance.submit();
  };

  const handleValuesChange = async (changedValues: Partial<FormValues>) => {
    if (searchName in changedValues) {
      debouncedHandleSubmit();
    } else {
      handleSubmit();
    }
  };

  const handleFormSubmit = async () => {
    handleSubmit();
    close();
  };

  const handleSubmit = useCallback(() => {
    const values = getFormValues();

    setSearchParams((prevSearchParams) => {
      const params = url.decodeParams(prevSearchParams.toString());
      const query = url.encodeParams({ ...params, [config.FILTERS_QUERY_PARAM]: values });

      return new URLSearchParams(query);
    }, {
      replace: true,
    });

    onSubmit(values);
  }, [onSubmit, setSearchParams, getFormValues]);

  const debouncedHandleSubmit = debounce(handleSubmit, 500);

  const renderCount = () => {
    const values = getFormValues();

    // Exclude visible filters
    delete values[searchName];

    const activeValues = Object.values(values);

    if (!activeValues.length) {
      return null;
    }

    return `(${activeValues.length})`;
  };

  useEffect(() => {
    const values = url.decodeParams(searchParams.toString())[config.FILTERS_QUERY_PARAM] as Partial<FormValues> | undefined;

    if (values) {
      formInstance.reset();
      formInstance.setFieldsValue(values as object);
    }

    formInstance.submit();
  }, [formInstance]); /* eslint-disable-line react-hooks/exhaustive-deps */

  return (
    <Form
      className={styles.root}
      form={formInstance}
      initialValues={values}
      onValuesChange={handleValuesChange}
      onFinish={handleFormSubmit}
    >

      <Flex className={styles.panel} gap="small" wrap="wrap">

        <div className={styles.search}>
          {withSearch && (
            <Form.Item name={searchName}>
              <Search />
            </Form.Item>
          )}
        </div>

        {inline ? children : (
          <Button type="default" icon={<Filter />} onClick={open}>
            {lang.get('common.actions.filters')} {renderCount()}
          </Button>
        )}

      </Flex>

      {!inline && (
        <Drawer
          title={lang.get('common.actions.filters')}
          footer={(
            <Button type="default" block onClick={handleCleanAllClick}>
              {lang.get('common.actions.cleanAll')}
            </Button>
          )}
          open={opened}
          onClose={close}
        >
          {children}
        </Drawer>
      )}

    </Form>
  );
};

export default Filters;
