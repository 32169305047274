import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { config } from 'data';
import { formatter, url } from 'helpers';
import { businessAccountService } from 'services';
import { fetchPaginatedResponseFully } from 'services/helpers';
import { useLang, useTable, useTableQuery } from 'hooks';
import { useBusinessAccountsQuery } from 'hooks/queries';
import { BusinessAccountIcon, BusinessAccountStatus, TableView } from 'components/layout';
import { Form, Select, Table, Tag } from 'components/ui';
import { ExportColumns, TableColumns } from 'types/components';
import { BusinessAccountsParams } from 'types/services';

import {
  BusinessAccount,
  BusinessAccountApiStatus as EBusinessAccountApiStatus,
  BusinessAccountStatus as EBusinessAccountStatus,
  BusinessAccountType as EBusinessAccountType,
} from 'types/models';

import styles from './styles.module.css';

const EXPORT_FILE_NAME = 'business-accounts';

type TableParams = {
  search?: string;
  type?: EBusinessAccountType;
  status?: EBusinessAccountStatus;
  apiStatus?: EBusinessAccountApiStatus;
};

const initialTableParams: TableParams = {
  status: EBusinessAccountStatus.ACTIVE,
};

const BusinessAccountsView = () => {
  const lang = useLang();
  const navigate = useNavigate();
  const table = useTable<BusinessAccount, TableParams>([config.BUSINESS_ACCOUNTS_QUERY_KEY], initialTableParams);

  const businessAccountsParams: BusinessAccountsParams = {
    page: table.page,
    search: table.params.search || undefined,
    type: table.params.type || undefined,
    status: table.params.status || undefined,
    enabledApi: table.params.apiStatus ? table.params.apiStatus === EBusinessAccountApiStatus.ENABLED : undefined,
  };

  const businessAccountsQuery = useBusinessAccountsQuery(businessAccountsParams);

  const handleViewClick = (businessAccount: BusinessAccount) => () => navigate(url.toBusinessAccount(businessAccount.id));

  useTableQuery(table, businessAccountsQuery);

  const getBusinessAccountBalanceCurrency = (businessAccount: BusinessAccount) => businessAccount.balance?.currency ?? config.DEFAULT_CURRENCY;

  const formatBusinessAccountName = (businessAccount: BusinessAccount) => businessAccount.name;

  const formatBusinessAccountApiAccess = useCallback((businessAccount: BusinessAccount) => lang.get(businessAccount.enabledApi ? 'businessAccount.apiStatuses.enabled' : 'businessAccount.apiStatuses.disabled'), [lang]);

  const exportColumns: ExportColumns<BusinessAccount> = useMemo(() => [
    {
      title: lang.get('businessAccount.list.type'),
      render: (businessAccount: BusinessAccount) => lang.get(`businessAccount.types.${businessAccount.type.toLowerCase()}`),
    }, {
      title: lang.get('businessAccount.list.name'),
      render: (businessAccount: BusinessAccount) => formatBusinessAccountName(businessAccount),
    }, {
      title: lang.get('businessAccount.list.balanceCurrency'),
      render: (businessAccount) => getBusinessAccountBalanceCurrency(businessAccount),
    }, {
      title: lang.get('businessAccount.list.balanceAvailable'),
      render: (businessAccount) => formatter.formatNumber(businessAccount.balance?.totalBalance ?? 0),
    }, {
      title: lang.get('businessAccount.list.balanceOutgoingPending'),
      render: (businessAccount) => formatter.formatNumber(businessAccount.balance?.totalOutgoingPendingBalance ?? 0),
    }, {
      title: lang.get('businessAccount.list.balanceIncomingPending'),
      render: (businessAccount: BusinessAccount) => formatter.formatNumber(businessAccount.balance?.totalIncomingPendingBalance ?? 0),
    }, {
      title: lang.get('businessAccount.list.balanceTotal'),
      render: (businessAccount) => formatter.formatNumber((businessAccount.balance?.totalBalance ?? 0) + Math.abs(businessAccount.balance?.totalOutgoingPendingBalance ?? 0)),
    }, {
      title: lang.get('businessAccount.list.status'),
      render: (businessAccount) => lang.get(`businessAccount.statuses.${businessAccount.status.toLowerCase()}`),
    }, {
      title: lang.get('businessAccount.list.apiAccess'),
      render: (businessAccount) => formatBusinessAccountApiAccess(businessAccount),
    },
  ], [lang, formatBusinessAccountApiAccess]);

  const tableColumns: TableColumns<BusinessAccount> = [
    {
      className: styles.table__name,
      key: 'name',
      title: lang.get('businessAccount.list.name'),
      render: (_, businessAccount) => (
        <Table.Truncate title={formatBusinessAccountName(businessAccount)} width="middle">
          <BusinessAccountIcon businessAccountType={businessAccount.type} />
          <span>{formatBusinessAccountName(businessAccount)}</span>
        </Table.Truncate>
      ),
    }, {
      key: 'balance',
      title: lang.get('businessAccount.list.balance'),
      render: (_, businessAccount) => {
        const balance = businessAccount.balance;
        const currency = getBusinessAccountBalanceCurrency(businessAccount);

        return (
          <div className={styles.balance}>
            <div className={styles.balance__item}>
              <strong>{formatter.formatCurrency(balance?.totalBalance ?? 0, currency)}</strong>
            </div>
            <div className={styles.balance__item}>
              <span>{formatter.formatCurrency(balance?.totalOutgoingPendingBalance ?? 0, currency)}</span>
              {lang.get('businessAccount.balance.outgoingPending')}
            </div>
            <div className={styles.balance__item}>
              <span>{formatter.formatCurrency(balance?.totalIncomingPendingBalance ?? 0, currency)}</span>
              {lang.get('businessAccount.balance.incomingPending')}
            </div>
          </div>
        );
      },
    }, {
      key: 'status',
      title: lang.get('businessAccount.list.status'),
      render: (_, businessAccount) => <BusinessAccountStatus status={businessAccount.status} />,
    }, {
      key: 'apiAccess',
      title: lang.get('businessAccount.list.apiAccess'),
      render: (_, businessAccount) => (
        <Tag color={businessAccount.enabledApi ? 'success' : 'default'}>
          {formatBusinessAccountApiAccess(businessAccount)}
        </Tag>
      ),
    },
  ];

  return (
    <TableView
      title={lang.get('businessAccount.list.title')}
      actions={(
        <TableView.ExportButton<BusinessAccount>
          table={table}
          type="default"
          fileName={EXPORT_FILE_NAME}
          columns={exportColumns}
          fetchData={() => fetchPaginatedResponseFully(businessAccountService.getBusinessAccounts, businessAccountsParams)}
        />
      )}
    >

      <TableView.Filters<TableParams>
        initialValues={initialTableParams}
        values={table.params}
        withSearch
        onSubmit={table.setParams}
      >
        <Form.Item name="type" label={lang.get('common.filters.type')}>
          <Select
            placeholder={lang.get('common.actions.all')}
            options={Object.values(EBusinessAccountType).map((type) => ({
              value: type,
              label: lang.get(`businessAccount.types.${type.toLowerCase()}`),
            }))}
            allowClear
          />
        </Form.Item>
        <Form.Item name="status" label={lang.get('common.filters.status')}>
          <Select
            placeholder={lang.get('common.actions.all')}
            options={Object.values(EBusinessAccountStatus).map((status) => ({
              value: status,
              label: lang.get(`businessAccount.statuses.${status.toLowerCase()}`),
            }))}
            allowClear
          />
        </Form.Item>
        <Form.Item name="apiStatus" label={lang.get('businessAccount.filters.apiStatus')}>
          <Select
            placeholder={lang.get('common.actions.all')}
            options={Object.values(EBusinessAccountApiStatus).map((status) => ({
              value: status,
              label: lang.get(`businessAccount.apiStatuses.${status.toLowerCase()}`),
            }))}
            allowClear
          />
        </Form.Item>
      </TableView.Filters>

      <TableView.Table<BusinessAccount>
        table={table}
        columns={tableColumns}
        rowKey={(businessAccount) => businessAccount.id}
        loading={businessAccountsQuery.isFetching}
        clickable
        onRow={(businessAccount) => ({ onClick: handleViewClick(businessAccount) })}
      />

    </TableView>
  );
};

export default BusinessAccountsView;
