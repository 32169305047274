import { Nullable, Uuid } from './common';

import {
  AuditLog,
  AuditLogAction,
  AuditLogObject,
  Bundle,
  BundleStatus,
  BusinessAccount,
  BusinessAccountMember,
  BusinessAccountRole,
  BusinessAccountRoleId,
  BusinessAccountStatus,
  BusinessAccountType,
  BusinessAccountVirtualAccount,
  BusinessApplication,
  BusinessApplicationAddress,
  BusinessApplicationStatus,
  Client,
  ClientStatus,
  Cost,
  Country,
  CountryCode,
  Currency,
  CurrencyCode,
  CurrencyRate,
  FeeCommission,
  FeeCommissionFrequency,
  FeeCommissionLevel,
  FeeGroup,
  ProviderBasic,
  ProviderFrequency,
  ProviderSimple,
  ReportBusinessAccountsBalancesItem,
  ReportBusinessAccountsDailyBalanceItem,
  ReportBusinessAccountsDailyRevenueItem,
  ReportDailyBalanceItem,
  ReportDailyRemittanceItem,
  ReportDailyTransactionItem,
  ReportProvidersDailyBalanceItem,
  ReportProvidersDailySettlementItem,
  ReportProvidersDailySettlementStatus,
  Topic,
  Transaction,
  TransactionDirection,
  TransactionFeeType,
  TransactionPayoutMethod,
  TransactionStatus,
  TransactionType,
  User,
  UserRole,
  UserRolePermission,
} from './models';

export enum AuthEvent {
  CREDENTIALS = 'credentials',
}

export enum AuthEndpoint {
  CHANGE_PASSWORD = '/core/admin/auth/change-password',
  LOGIN = '/core/admin/auth/sign-in',
  LOGOUT = '/core/admin/auth/sign-out',
  REFRESH_ACCESS_TOKEN = '/core/admin/auth/refresh',
  RESET_PASSWORD = '/core/admin/auth/forgot-password',
  RESET_PASSWORD_CONFIRM = '/core/admin/auth/forgot-password-confirm',
}

export type DateRangeable = {
  dateFrom?: string;
  dateTo?: string;
};

export type Paginatable = {
  page?: number;
  size?: number;
};

export type PaginatedResponse<Type> = {
  content: Type[];
  totalElements: number;
  pageable: {
    pageNumber: number;
    pageSize: number;
  };
};

export type PaginatedData<Type> = {
  list: Type[];
  page: number;
  size: number;
  total: number;
};

export type Timezonable = {
  timezone?: string;
};

export type AuditLogsParams = DateRangeable & Paginatable & Timezonable & {
  userId?: Uuid;
  search?: string;
  action?: AuditLogAction;
  objectType?: AuditLogObject;
};

export type AuditLogsResponse = PaginatedResponse<AuditLog>;

export type AuthChallenge = {
  challengeName: string;
  session: string;
  email: string;
};

export type AuthCredentials = {
  accessToken: string;
  refreshToken: string;
};

export type AuthLoginParams = {
  email: string;
  password: string;
};

export type AuthLoginResponse = AuthCredentials | AuthChallenge;

export type AuthRefreshAccessTokenParams = {
  refreshToken: string;
};

export type AuthChangePasswordParams = {
  hash: string;
  session: string;
  email: string;
  newPassword: string;
};

export type AuthRefreshAccessTokenResponse = {
  accessToken: string;
};

export type AuthResetPasswordParams = {
  email: string;
};

export type AuthResetPasswordConfirmParams = {
  email: string;
  password: string;
  code: string;
};

export type BundlesParams = DateRangeable & Paginatable & Timezonable & {
  search?: string;
  status?: BundleStatus[];
};

export type BundlesResponse = PaginatedResponse<Bundle>;

export type BundleApproveParams = {
  virtualAccountId: Uuid;
};

export type BundleCreateParams = {
  clientId: Uuid;
  fileId: Uuid;
};

export type BusinessAccountsParams = Paginatable & {
  search?: string;
  type?: BusinessAccountType;
  status?: BusinessAccountStatus;
  enabledApi?: boolean;
};

export type BusinessAccountsResponse = PaginatedResponse<BusinessAccount>;

export type BusinessAccountsBalanceParams = {
  clientIds: Uuid[];
};

export type BusinessAccountUpdateDetailsParams = Partial<{
  currencies: CurrencyCode[];
  feeCommissionIds: Nullable<Uuid[]>;
  feeGroupId: Nullable<Uuid>;
  lowBalanceLevel: Nullable<number>;
  webhookUrl: Nullable<Uuid>;
}>;

export type BusinessAccountMembersResponse = {
  list: BusinessAccountMember[];
};

export type BusinessAccountAddMemberParams = {
  roleIds: BusinessAccountRoleId[];
};

export type BusinessAccountUpdateMemberRolesParams = {
  roleIds: BusinessAccountRoleId[];
};

export type BusinessAccountRolesResponse = {
  list: BusinessAccountRole[];
};

export type BusinessAccountVirtualAccountsResponse = {
  list: BusinessAccountVirtualAccount[];
};

export type BusinessApplicationsParams = Paginatable & {
  search?: string;
  status?: BusinessApplicationStatus;
};

export type BusinessApplicationsResponse = PaginatedResponse<BusinessApplication>;

export type BusinessApplicationParams = {
  industryId: Uuid;
  name: string;
  email: string;
  activity: string;
};

export type BusinessApplicationRejectParams = {
  rejectReason: string;
};

export type BusinessApplicationVerifyParams = {
  externalCode: string;
  registryDate: string;
  isRegistryAddressSameAsPrincipal: boolean;
  principalAddress: BusinessApplicationAddress;
  registryAddress: BusinessApplicationAddress;
};

export type BusinessApplicationDetailsUpdateParams = {
  averageAccountBalance?: Nullable<number>;
  fallBelowFee?: Nullable<string>;
  annualMaintenanceFees?: Nullable<string>;
  accountOpeningFees?: Nullable<string>;
  notes?: Nullable<string>;
};

export type ClientsParams = Paginatable & {
  country?: CountryCode;
  search?: string;
  status?: ClientStatus;
};

export type ClientsResponse = PaginatedResponse<Client>;

export type ClientParams = {
  status: ClientStatus;
};

export type CostsParams = Paginatable & {
  providerId?: Uuid;
  country?: CountryCode;
  currency?: CurrencyCode;
  active?: boolean;
};

export type CostsResponse = PaginatedResponse<Cost>;

export type CostParams = Timezonable & {
  providerId: Uuid;
  country?: Nullable<CountryCode>;
  currency?: Nullable<CurrencyCode>;
  transactionFeeType: TransactionFeeType;
  transactionFee: number;
  fxMarkup: number;
  minTransactionFeeAmount: number;
  maxTransactionFeeAmount: number;
  startDate?: Nullable<string>;
  endDate?: Nullable<string>;
  notes?: Nullable<string>;
  active?: boolean;
  c2c?: Nullable<boolean>;
  c2b?: Nullable<boolean>;
  b2c?: Nullable<boolean>;
  b2b?: Nullable<boolean>;
};

export type CountriesResponse = {
  list: Country[];
};

export type CurrenciesParams = {
  active?: boolean;
};

export type CurrenciesResponse = {
  list: Currency[];
};

export type CurrenciesUpdateParams = {
  currencies: CurrencyCode[];
};

export type CurrencyRatesParams = DateRangeable & Paginatable & Timezonable & {
  currency?: CurrencyCode;
};

export type CurrencyRatesResponse = PaginatedResponse<CurrencyRate>;

export type CurrencyRateParams = {
  baseCurrency: CurrencyCode;
  targetCurrency: CurrencyCode;
  rate: number;
};

export type FeeCommissionsParams = Paginatable & {
  level?: FeeCommissionLevel;
  transactionType?: TransactionType;
  direction?: TransactionDirection;
  country?: CountryCode;
  currency?: CurrencyCode;
};

export type FeeCommissionsResponse = PaginatedResponse<FeeCommission>;

export type FeeCommissionParams = Timezonable & {
  feeGroupId?: Nullable<Uuid>;
  name: string;
  level: FeeCommissionLevel;
  transactionType: TransactionType;
  direction: Nullable<TransactionDirection>;
  feeFrequency?: Nullable<FeeCommissionFrequency>;
  country?: Nullable<CountryCode>;
  currency?: Nullable<CurrencyCode>;
  transactionFeeCurrency: CurrencyCode;
  fixedTransactionFee?: Nullable<number>;
  percentTransactionFee?: Nullable<number>;
  minTransactionFeeAmount?: Nullable<number>;
  maxTransactionFeeAmount?: Nullable<number>;
  fxMarkup: number;
  startDate?: Nullable<string>;
  endDate?: Nullable<string>;
  description?: Nullable<string>;
};

export type FeeGroupsParams = Paginatable;

export type FeeGroupsResponse = PaginatedResponse<FeeGroup>;

export type FeeGroupParams = {
  name: string;
  description: string;
};

export type ProfileResponse = {
  id: Uuid;
  firstName: Nullable<string>;
  lastName: Nullable<string>;
  email: string;
  phone: Nullable<string>;
};

export type ProfileUpdateParams = {
  firstName?: Nullable<string>;
  lastName?: Nullable<string>;
  phone?: Nullable<string>;
};

export type ProfileUpdatePasswordParams = {
  oldPassword: string;
  newPassword: string;
};

export type ProfileTopicsParams = Paginatable;

export type ProfileTopicsResponse = PaginatedResponse<Topic>;

export type ProfileUpdateTopic = {
  id: Uuid;
  subscribe: boolean;
};

export type ProfileUpdateTopicsParams = {
  topics: ProfileUpdateTopic[];
};

export type ProvidersParams = Paginatable & {
  search?: string;
  active?: boolean;
};

export type ProvidersResponse = PaginatedResponse<ProviderSimple>;

export type ProvidersBasicResponse = PaginatedResponse<ProviderBasic>;

export type ProviderParams = {
  frequency?: Nullable<ProviderFrequency>;
  minFeeAmount: number;
  currency: CurrencyCode;
  notes?: Nullable<string>;
  active?: boolean;
  files?: Uuid[];
};

export type ReportBusinessAccountsBalancesParams = Paginatable;

export type ReportBusinessAccountsBalancesResponse = PaginatedResponse<ReportBusinessAccountsBalancesItem>;

export type ReportBusinessAccountsDailyBalanceParams = Required<DateRangeable> & Paginatable & Timezonable & {
  search?: string;
};

export type ReportBusinessAccountsDailyBalanceResponse = PaginatedResponse<ReportBusinessAccountsDailyBalanceItem>;

export type ReportBusinessAccountsDailyRevenueParams = Required<DateRangeable> & Paginatable & Timezonable & {
  providerId?: Uuid;
  search?: string;
  country?: CountryCode;
  payoutMethod?: TransactionPayoutMethod;
};

export type ReportBusinessAccountsDailyRevenueResponse = PaginatedResponse<ReportBusinessAccountsDailyRevenueItem>;

export type ReportDailyBalanceParams = Required<DateRangeable> & Paginatable & Timezonable & {
  clientId?: Uuid;
};

export type ReportDailyBalanceResponse = PaginatedResponse<ReportDailyBalanceItem>;

export type ReportDailyRemittanceParams = Required<DateRangeable> & Paginatable & Timezonable & {
  clientIds?: Uuid[];
};

export type ReportDailyRemittanceResponse = PaginatedResponse<ReportDailyRemittanceItem>;

export type ReportDailyTransactionsParams = Required<DateRangeable> & Paginatable & Timezonable & {
  clientId?: Uuid;
};

export type ReportDailyTransactionsResponse = PaginatedResponse<ReportDailyTransactionItem>;

export type ReportProvidersDailyBalanceParams = Required<DateRangeable> & Paginatable & Timezonable & {
  providerId?: Uuid;
};

export type ReportProvidersDailyBalanceResponse = PaginatedResponse<ReportProvidersDailyBalanceItem>;

export type ReportProvidersDailySettlementParams = Required<DateRangeable> & Paginatable & Timezonable & {
  providerId?: Uuid;
  currency?: CurrencyCode;
  includeClientInfo?: boolean;
  status?: ReportProvidersDailySettlementStatus;
};

export type ReportProvidersDailySettlementResponse = PaginatedResponse<ReportProvidersDailySettlementItem>;

export type ReportProvidersDailySettlementSettleTransactions = {
  transactionIds: Uuid[];
  settle: boolean;
};

export type TransactionsParams = DateRangeable & Paginatable & Timezonable & {
  clientId?: Uuid;
  providerId?: Uuid;
  country?: CountryCode;
  destinationCurrency?: CurrencyCode;
  direction?: TransactionDirection;
  originCurrency?: CurrencyCode;
  payoutMethod?: TransactionPayoutMethod;
  search?: string;
  status?: TransactionStatus[];
  transactionType?: TransactionType[];
};

export type TransactionsResponse = PaginatedResponse<Transaction>;

export type TransactionCreateParams = {
  clientId: Uuid;
  virtualAccountId: Uuid;
  referenceId?: Nullable<string>;
  amount: number;
  currency: CurrencyCode;
  notes?: Nullable<string>;
};

export type TransactionUpdateParams = Partial<{
  virtualAccountId: Uuid;
  referenceId: Nullable<string>;
  amount: number;
  currency: CurrencyCode;
  notes: Nullable<string>;
  status: TransactionStatus;
}>;

export type UsersParams = Paginatable & {
  roleId?: Uuid;
};

export type UsersResponse = PaginatedResponse<User>;

export type UserCreateParams = {
  firstName?: Nullable<string>;
  lastName?: Nullable<string>;
  email: string;
  phone?: Nullable<string>;
  roleIds: Uuid[];
};

export type UserUpdateRolesParams = {
  roleIds: Uuid[];
};

export type UserRolesParams = Paginatable;

export type UserRolesResponse = PaginatedResponse<UserRole>;

export type UserRoleParams = {
  name: string;
  description?: Nullable<string>;
  permissions: UserRolePermission[];
};

export type UserTopicsParams = Paginatable;

export type UserTopicsResponse = PaginatedResponse<Topic>;
