import React, { FC } from 'react';
import { PresetStatusColorType } from 'antd/es/_util/colors';

import { useLang } from 'hooks';
import { Tag } from 'components/ui';
import { Override } from 'types/common';
import { TagProps } from 'types/components';
import { BundleStatus as EBundleStatus } from 'types/models';

const statusColors: Record<EBundleStatus, PresetStatusColorType> = {
  [EBundleStatus.APPROVED]: 'processing',
  [EBundleStatus.COMPLETED]: 'success',
  [EBundleStatus.FAILED]: 'error',
  [EBundleStatus.IN_PROGRESS]: 'processing',
  [EBundleStatus.NEW]: 'warning',
  [EBundleStatus.REJECTED]: 'default',
  [EBundleStatus.VALIDATION_FAILED]: 'error',
};

type BundleStatusProps = Override<Omit<TagProps, 'color' | 'children'>, {
  status: EBundleStatus;
}>;

const BundleStatus: FC<BundleStatusProps> = ({ status, ...props }) => {
  const lang = useLang();

  return (
    <Tag color={statusColors[status]} {...props}>
      {lang.get(`bundle.statuses.${status.toLowerCase()}`)}
    </Tag>
  );
};

export default BundleStatus;
