import React from 'react';

import { useLang, useModal } from 'hooks';
import { useProvidersQuery } from 'hooks/queries';
import { CardView } from 'components/layout';
import { Button } from 'components/ui';

import ListModal from './ListModal';

const ProvidersView = () => {
  const lang = useLang();
  const modal = useModal();

  const activeProvidersQuery = useProvidersQuery({ active: true });

  return (
    <>

      <CardView
        title={lang.get('provider.list.title')}
        caption={lang.choice('provider.list.caption', activeProvidersQuery.data?.total ?? 0)}
        actions={(
          <Button
            color="primary"
            variant="filled"
            loading={activeProvidersQuery.isFetching}
            onClick={modal.show}
          >
            {lang.get('common.actions.edit')}
          </Button>
        )}
      />

      <ListModal
        open={modal.open}
        onClose={modal.hide}
      />

    </>
  );
};

export default ProvidersView;
