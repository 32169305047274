import { config } from 'data';
import { bundleService } from 'services';
import { useQuery } from 'hooks';
import { BundlesParams } from 'types/services';

const useBundlesQuery = (params?: BundlesParams) => useQuery({
  queryKey: [config.BUNDLES_QUERY_KEY, params],
  queryFn: () => bundleService.getBundles(params),
});

export default useBundlesQuery;
