import { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { url } from 'helpers';
import { useAuth } from 'hooks';
import { RouteProps } from 'types/routes';

const PrivateRoute: FC<RouteProps> = ({ component }) => {
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth.authenticated) {
      const returnUrl = location.pathname + location.search;

      navigate(url.toLogin(), { state: { returnUrl } });
    }
  }, [auth.authenticated, location.pathname, location.search, navigate]);

  return auth.authenticated && component;
};

export default PrivateRoute;
