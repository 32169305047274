import React from 'react';

import { useLang } from 'hooks';
import { DashboardLayout } from 'layouts';
import { BusinessAccountBalance, BusinessAccountIcon, SinglePage, TransactionsView } from 'components/layout';

const BusinessAccountTransactionsPage = () => {
  const lang = useLang();

  return (
    <SinglePage.BusinessAccount render={(businessAccount) => (
      <DashboardLayout title={lang.get('businessAccount.transactions.title', { name: businessAccount.name })}>
        <TransactionsView
          icon={<BusinessAccountIcon businessAccountType={businessAccount.type} size="large" />}
          title={lang.get('businessAccount.transactions.title', { name: businessAccount.name })}
          caption={<BusinessAccountBalance businessAccount={businessAccount} />}
          businessAccount={businessAccount}
        />
      </DashboardLayout>
    )} />
  );
};

export default BusinessAccountTransactionsPage;
