import React from 'react';

import { useLang, useModal } from 'hooks';
import { useFeeGroupsQuery } from 'hooks/queries';
import { CardView } from 'components/layout';
import { Button } from 'components/ui';

import ListModal from './ListModal';

const FeeGroupsView = () => {
  const lang = useLang();
  const modal = useModal();

  const feeGroupsQuery = useFeeGroupsQuery();

  return (
    <>

      <CardView
        title={lang.get('feeGroup.list.title')}
        caption={lang.choice('feeGroup.list.caption', feeGroupsQuery.data?.total ?? 0)}
        actions={(
          <Button
            color="primary"
            variant="filled"
            loading={feeGroupsQuery.isFetching}
            onClick={modal.show}
          >
            {lang.get('common.actions.edit')}
          </Button>
        )}
      />

      <ListModal
        open={modal.open}
        onClose={modal.hide}
      />

    </>
  );
};

export default FeeGroupsView;
