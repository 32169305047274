import { axios } from 'data';
import { Uuid } from 'types/common';
import { FeeGroup } from 'types/models';
import { FeeGroupParams, FeeGroupsParams, FeeGroupsResponse } from 'types/services';

import { preparePaginatableParams, preparePaginatedData } from './helpers';

const getFeeGroups = async (params: FeeGroupsParams = {}) => {
  const response = await axios.get<FeeGroupsResponse>('/payments/admin/fee-group', {
    params: preparePaginatableParams(params),
  });

  return preparePaginatedData<FeeGroup>(response.data);
};

const createFeeGroup = async (params: FeeGroupParams) => {
  await axios.post<never>('/payments/admin/fee-group', params);
};

const updateFeeGroup = async (id: Uuid, params: FeeGroupParams) => {
  await axios.patch<never>(`/payments/admin/fee-group/${id}`, params);
};

const deleteFeeGroup = async (id: Uuid) => {
  await axios.delete<never>(`/payments/admin/fee-group/${id}`);
};

const feeGroupService = {
  getFeeGroups,
  createFeeGroup,
  updateFeeGroup,
  deleteFeeGroup,
};

export default feeGroupService;
