import React, { useState } from 'react';
import map from 'lodash/map';

import { config } from 'data';
import { useLang, useModal, useTable, useTableQuery } from 'hooks';
import { useUserQuery, useUsersQuery } from 'hooks/queries';
import { Plus } from 'components/icons';
import { TableView } from 'components/layout';
import { Button, Form, Table } from 'components/ui';
import { Uuid } from 'types/common';
import { TableColumns } from 'types/components';
import { User } from 'types/models';

import styles from './styles.module.css';

import CreateModal from './CreateModal';
import RoleSelect from './RoleSelect';
import ViewModal from './ViewModal';

type TableParams = {
  roleId?: Uuid;
};

const initialTableParams: TableParams = {
  //
};

const UsersView = () => {
  const lang = useLang();
  const createModal = useModal();
  const viewModal = useModal();
  const table = useTable<User, TableParams>([config.USERS_QUERY_KEY], initialTableParams);

  const [userId, setUserId] = useState<Uuid>();

  const userQuery = useUserQuery(userId);
  const usersQuery = useUsersQuery({
    page: table.page,
    roleId: table.params.roleId || undefined,
  });

  const handleViewClick = (user: User) => () => {
    setUserId(user.id);

    viewModal.show();
  };

  const handleDelete = () => {
    setUserId(undefined);
  };

  useTableQuery(table, usersQuery);

  const columns: TableColumns<User> = [
    {
      className: styles.table__email,
      key: 'email',
      title: lang.get('user.list.email'),
      render: (_, user) => <Table.Truncate width="middle">{user.email}</Table.Truncate>,
    }, {
      key: 'firstName',
      title: lang.get('user.list.firstName'),
      render: (_, user) => <Table.Truncate>{user.firstName || '-'}</Table.Truncate>,
    }, {
      key: 'lastName',
      title: lang.get('user.list.lastName'),
      render: (_, user) => <Table.Truncate>{user.lastName || '-'}</Table.Truncate>,
    }, {
      key: 'roles',
      title: lang.get('user.list.roles'),
      render: (_, user) => map(user.roles, 'name').join(', ') || '-',
    },
  ];

  return (
    <TableView
      title={lang.get('user.list.title')}
      actions={(
        <Button icon={<Plus />} onClick={createModal.show}>
          {lang.get('user.list.create')}
        </Button>
      )}
    >

      <TableView.Filters<TableParams>
        initialValues={initialTableParams}
        values={table.params}
        inline
        onSubmit={table.setParams}
      >
        <Form.Item name="roleId">
          <RoleSelect
            placeholder={lang.get('common.filters.roles')}
            allowClear
            popupMatchSelectWidth={false}
          />
        </Form.Item>
      </TableView.Filters>

      <TableView.Table<User>
        table={table}
        columns={columns}
        rowKey={(user) => user.id}
        loading={usersQuery.isFetching}
        clickable
        onRow={(user) => ({ onClick: handleViewClick(user) })}
      />

      <CreateModal
        open={createModal.open}
        onClose={createModal.hide}
      />

      {userQuery.data && (
        <ViewModal
          user={userQuery.data}
          open={!userQuery.isFetching && viewModal.open}
          onClose={viewModal.hide}
          onDelete={handleDelete}
        />
      )}

    </TableView>
  );
};

export default UsersView;
