import { axios } from 'data';
import { AuditLog } from 'types/models';
import { AuditLogsParams, AuditLogsResponse } from 'types/services';

import { preparePaginatableParams, preparePaginatedData, prepareTimezonableParams } from './helpers';

const getLogs = async (params: AuditLogsParams = {}) => {
  params = prepareTimezonableParams(params);
  params = preparePaginatableParams(params);

  const response = await axios.get<AuditLogsResponse>('/payments/admin/audit', { params });

  return preparePaginatedData<AuditLog>(response.data);
};

const auditLogService = {
  getLogs,
};

export default auditLogService;
