import React, { FC, useEffect } from 'react';

import { config, moment, yup } from 'data';
import { useForm, useFormWatch, useLang } from 'hooks';
import { useBusinessApplicationKybDetailsQuery } from 'hooks/queries';
import { CountrySelect } from 'components/layout';
import { Checkbox, DatePicker, Form, Input, Modal } from 'components/ui';
import { ModalBaseProps } from 'types/components';
import { BusinessApplication, BusinessApplicationAddress } from 'types/models';
import { BusinessApplicationVerifyParams } from 'types/services';

type FormValues = BusinessApplicationVerifyParams;

const initialAddress: BusinessApplicationAddress = {
  countryCode: '',
  state: '',
  city: '',
  zip: '',
  street: '',
};

const initialValues: FormValues = {
  externalCode: '',
  registryDate: '',
  isRegistryAddressSameAsPrincipal: true,
  registryAddress: initialAddress,
  principalAddress: initialAddress,
};

const addressValidationSchema = yup.object({
  countryCode: yup.string().required().country(),
  state: yup.string().required().min(config.STATE_MIN_LENGTH).max(config.STATE_MAX_LENGTH),
  city: yup.string().required().min(config.CITY_MIN_LENGTH).max(config.CITY_MAX_LENGTH),
  zip: yup.string().required().min(config.ZIP_MIN_LENGTH).max(config.ZIP_MAX_LENGTH),
  street: yup.string().required().min(config.STREET_MIN_LENGTH).max(config.STREET_MAX_LENGTH),
});

const validationSchema = yup.object({
  externalCode: yup.string().required().max(config.STRING_MAX_LENGTH),
  registryDate: yup.string().required(),
  isRegistryAddressSameAsPrincipal: yup.boolean().required(),
  registryAddress: addressValidationSchema,
  principalAddress: addressValidationSchema,
});

type VerifyBusinessApplicationModalProps = ModalBaseProps & {
  businessApplication: BusinessApplication;
  loading?: boolean;
  onSubmit: (values: FormValues) => Promise<void>;
};

const VerifyBusinessApplicationModal: FC<VerifyBusinessApplicationModalProps> = ({
  businessApplication,
  open,
  loading,
  onClose,
  onSubmit,
}) => {
  const form = useForm<FormValues>();
  const lang = useLang();

  const currentIsSameAddress = useFormWatch('isRegistryAddressSameAsPrincipal', form);
  const currentRegistryAddress = useFormWatch('registryAddress', form);

  const kybDetailsQuery = useBusinessApplicationKybDetailsQuery(businessApplication.kybDetails?.id);

  const handleSubmit = async (values: FormValues) => {
    await onSubmit(values);

    onClose();
  };

  useEffect(() => {
    if (open) {
      form.setFieldsValue({
        externalCode: businessApplication.kybDetails?.externalCode ?? initialValues.externalCode,
        registryDate: businessApplication.registryDate ?? initialValues.registryDate,
        isRegistryAddressSameAsPrincipal: businessApplication.isRegistryAddressSameAsPrincipal ?? initialValues.isRegistryAddressSameAsPrincipal,
        registryAddress: { ...businessApplication.registryAddress },
        principalAddress: { ...businessApplication.principalAddress },
      });
    }
  }, [businessApplication, open, form]);

  useEffect(() => {
    if (!businessApplication.registryAddress && kybDetailsQuery.data) {
      form.setFieldsValue({ registryAddress: { ...kybDetailsQuery.data } });
    }
  }, [businessApplication, form, kybDetailsQuery.data]);

  useEffect(() => {
    if (form.isFieldTouched('isRegistryAddressSameAsPrincipal')) {
      if (currentIsSameAddress) {
        form.setFieldsValue({ principalAddress: currentRegistryAddress });
      } else {
        form.resetFields([
          ['principalAddress', 'countryCode'],
          ['principalAddress', 'state'],
          ['principalAddress', 'city'],
          ['principalAddress', 'zip'],
          ['principalAddress', 'street'],
        ]);
      }
    }
  }, [form, currentIsSameAddress, currentRegistryAddress]);

  return (
    <Modal
      title={lang.get('businessApplication.verifyModal.title', { name: businessApplication.name })}
      caption={lang.get('businessApplication.verifyModal.caption')}
      okText={lang.get('common.actions.verify')}
      width="small"
      open={open}
      confirmLoading={loading}
      onOk={form.submit}
      onCancel={onClose}
    >
      <Form
        form={form}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onFinish={handleSubmit}
      >

        <Form.Columns>
          <Form.Item name="externalCode" label={lang.get('businessApplication.verifyModal.registryNumber.label')}>
            <Input placeholder={lang.get('businessApplication.verifyModal.registryNumber.placeholder')} />
          </Form.Item>
          <Form.Item name="registryDate" label={lang.get('businessApplication.verifyModal.registryDate.label')}>
            <DatePicker maxDate={moment().endOf('day')} allowClear={false} />
          </Form.Item>
        </Form.Columns>

        <Form.Divider />

        <Form.Title>{lang.get('businessApplication.verifyModal.registryAddress')}</Form.Title>
        <Form.Columns>
          <Form.Item name={['registryAddress', 'countryCode']} label={lang.get('common.form.country.label')}>
            <CountrySelect />
          </Form.Item>
          <Form.Item name={['registryAddress', 'state']} label={lang.get('businessApplication.verifyModal.state.label')}>
            <Input placeholder={lang.get('businessApplication.verifyModal.state.placeholder')} />
          </Form.Item>
        </Form.Columns>
        <Form.Columns>
          <Form.Item name={['registryAddress', 'city']} label={lang.get('businessApplication.verifyModal.city.label')}>
            <Input placeholder={lang.get('businessApplication.verifyModal.city.placeholder')} />
          </Form.Item>
          <Form.Item name={['registryAddress', 'zip']} label={lang.get('businessApplication.verifyModal.zip.label')}>
            <Input placeholder={lang.get('businessApplication.verifyModal.zip.placeholder')} />
          </Form.Item>
        </Form.Columns>
        <Form.Item name={['registryAddress', 'street']} label={lang.get('businessApplication.verifyModal.street.label')}>
          <Input placeholder={lang.get('businessApplication.verifyModal.street.placeholder')} />
        </Form.Item>
        <Form.CheckboxItem name="isRegistryAddressSameAsPrincipal">
          <Checkbox>{lang.get('businessApplication.verifyModal.sameAddress')}</Checkbox>
        </Form.CheckboxItem>

        <Form.Divider hidden={currentIsSameAddress} />

        <Form.Title hidden={currentIsSameAddress}>{lang.get('businessApplication.verifyModal.principalAddress')}</Form.Title>
        <Form.Columns hidden={currentIsSameAddress}>
          <Form.Item name={['principalAddress', 'countryCode']} label={lang.get('common.form.country.label')}>
            <CountrySelect />
          </Form.Item>
          <Form.Item name={['principalAddress', 'state']} label={lang.get('businessApplication.verifyModal.state.label')}>
            <Input placeholder={lang.get('businessApplication.verifyModal.state.placeholder')} />
          </Form.Item>
        </Form.Columns>
        <Form.Columns hidden={currentIsSameAddress}>
          <Form.Item name={['principalAddress', 'city']} label={lang.get('businessApplication.verifyModal.city.label')}>
            <Input placeholder={lang.get('businessApplication.verifyModal.city.placeholder')} />
          </Form.Item>
          <Form.Item name={['principalAddress', 'zip']} label={lang.get('businessApplication.verifyModal.zip.label')}>
            <Input placeholder={lang.get('businessApplication.verifyModal.zip.placeholder')} />
          </Form.Item>
        </Form.Columns>
        <Form.Item name={['principalAddress', 'street']} label={lang.get('businessApplication.verifyModal.street.label')} hidden={currentIsSameAddress}>
          <Input placeholder={lang.get('businessApplication.verifyModal.street.placeholder')} />
        </Form.Item>

      </Form>
    </Modal>
  );
};

export default VerifyBusinessApplicationModal;
