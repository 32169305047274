import React, { FC, useEffect, useState } from 'react';

import { useLang } from 'hooks';
import { Checkbox, Empty, Flex, Form, Link, Modal } from 'components/ui';
import { ModalBaseProps } from 'types/components';
import { CurrencyCode } from 'types/models';

import styles from './styles.module.css';

type CurrenciesModalProps = ModalBaseProps & {
  initialCurrencyCodes: CurrencyCode[];
  currencyCodes: CurrencyCode[];
  onSubmit: (currencyCodes: CurrencyCode[]) => Promise<void>;
};

const CurrenciesModal: FC<CurrenciesModalProps> = ({
  initialCurrencyCodes,
  currencyCodes,
  open,
  onSubmit,
  onClose,
}) => {
  const lang = useLang();

  const [selectedCurrencyCodes, setSelectedCurrencyCodes] = useState<CurrencyCode[]>([]);
  const [submitting, setSubmitting] = useState(false);

  const handleSelectAllClick = () => handleChange(currencyCodes);

  const handleCleanAllClick = () => handleChange([]);

  const handleChange = setSelectedCurrencyCodes;

  const handleSubmit = async () => {
    try {
      setSubmitting(true);

      await onSubmit(selectedCurrencyCodes);

      onClose();
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (open) {
      setSelectedCurrencyCodes(initialCurrencyCodes);
    }
  }, [initialCurrencyCodes, open]);

  const isEmpty = !currencyCodes.length;

  return (
    <Modal
      title={lang.get('currency.modal.title')}
      caption={lang.choice('currency.modal.caption', selectedCurrencyCodes.length)}
      okText={lang.get('common.actions.save')}
      width="large"
      open={open}
      confirmLoading={submitting}
      onOk={handleSubmit}
      onCancel={onClose}
    >
      <Form>
        <Form.Item hidden={!isEmpty}>
          <Empty description={lang.get('currency.modal.empty')} />
        </Form.Item>
        <Form.Item hidden={isEmpty}>
          <Flex gap="small" justify="space-between" wrap="wrap">
            <Link onClick={handleSelectAllClick}>
              <strong>{lang.get('common.actions.selectAll')}</strong>
            </Link>
            <Link onClick={handleCleanAllClick}>
              <strong>{lang.get('common.actions.cleanAll')}</strong>
            </Link>
          </Flex>
        </Form.Item>
        <Form.Item hidden={isEmpty}>
          <Checkbox.Group
            className={styles.form__checkboxes}
            value={selectedCurrencyCodes}
            options={currencyCodes.map((code) => ({
              value: code,
              label: code,
            }))}
            onChange={handleChange}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CurrenciesModal;
