import React, { useEffect } from 'react';
import { createBrowserRouter, Link, Navigate, RouterProvider } from 'react-router-dom';

import { query } from 'data';
import { lang, url } from 'helpers';
import { useAuth, usePreloader } from 'hooks';
import { getBusinessAccountQuery } from 'hooks/queries';
import { PrivateRoute, PublicRoute } from 'components/routes';
import { BusinessAccountExtended } from 'types/models';

import {
  AuditLogsPage,
  AuthPage,
  BundlesPage,
  BusinessAccountDailyBalancePage,
  BusinessAccountDailyRemittancePage,
  BusinessAccountDailyTransactionsPage,
  BusinessAccountPage,
  BusinessAccountsDailyBalancePage,
  BusinessAccountsDailyRevenuePage,
  BusinessAccountsPage,
  BusinessAccountTransactionsPage,
  BusinessApplicationsPage,
  ClientsPage,
  CostManagementPage,
  CurrenciesPage,
  DailyRemittancePage,
  ErrorPage,
  FeeManagementPage,
  LoginPage,
  NotFoundPage,
  PasswordChangePage,
  PasswordResetPage,
  ProvidersDailyBalancePage,
  ProvidersDailySettlementPage,
  TransactionsPage,
  UsersPage,
} from 'pages';

const router = createBrowserRouter([
  {
    errorElement: <ErrorPage />,
    children: [
      {
        path: url.toAuth(':hash'),
        element: <AuthPage />,
      }, {
        path: url.toPasswordChange(':hash'),
        element: <PasswordChangePage />,
      }, {
        path: url.toLogin(),
        element: <PublicRoute component={<LoginPage />} />,
      }, {
        path: url.toPasswordReset(),
        element: <PublicRoute component={<PasswordResetPage />} />,
      }, {
        path: url.toDashboard(),
        handle: {
          crumb: () => <span>{lang.get('common.menu.dashboard')}</span>,
        },
        children: [
          {
            index: true,
            element: <Navigate to={url.toClients()} />,
          }, {
            path: url.toClients(),
            element: <PrivateRoute component={<ClientsPage />} />,
            handle: {
              crumb: () => (
                <Link to={url.toClients()}>
                  {lang.get('common.menu.clients')}
                </Link>
              ),
            },
          }, {
            path: url.toBusinessAccounts(),
            handle: {
              crumb: () => (
                <Link to={url.toBusinessAccounts()}>
                  {lang.get('common.menu.businessAccounts')}
                </Link>
              ),
            },
            children: [
              {
                index: true,
                element: <PrivateRoute component={<BusinessAccountsPage />} />,
              }, {
                path: url.toBusinessAccount(':id'),
                loader: ({ params }) => query.ensureQueryData(getBusinessAccountQuery(params.id)),
                handle: {
                  crumb: (businessAccount: BusinessAccountExtended) => (
                    <Link to={url.toBusinessAccount(businessAccount.id)}>
                      {businessAccount.name}
                    </Link>
                  ),
                },
                children: [
                  {
                    index: true,
                    element: <PrivateRoute component={<BusinessAccountPage />} />,
                  }, {
                    path: url.toBusinessAccountTransactions(':id'),
                    element: <PrivateRoute component={<BusinessAccountTransactionsPage />} />,
                    loader: ({ params }) => query.ensureQueryData(getBusinessAccountQuery(params.id)),
                    handle: {
                      crumb: (businessAccount: BusinessAccountExtended) => (
                        <Link to={url.toBusinessAccountTransactions(businessAccount.id)}>
                          {lang.get('common.menu.transactions')}
                        </Link>
                      ),
                    },
                  }, {
                    path: url.toBusinessAccountReports(':id'),
                    handle: {
                      crumb: () => <span>{lang.get('common.menu.reports')}</span>,
                    },
                    children: [
                      {
                        index: true,
                        element: <NotFoundPage />,
                      }, {
                        path: url.toBusinessAccountDailyBalance(':id'),
                        element: <PrivateRoute component={<BusinessAccountDailyBalancePage />} />,
                        loader: ({ params }) => query.ensureQueryData(getBusinessAccountQuery(params.id)),
                        handle: {
                          crumb: (businessAccount: BusinessAccountExtended) => (
                            <Link to={url.toBusinessAccountDailyBalance(businessAccount.id)}>
                              {lang.get('common.menu.dailyBalance')}
                            </Link>
                          ),
                        },
                      }, {
                        path: url.toBusinessAccountDailyRemittance(':id'),
                        element: <PrivateRoute component={<BusinessAccountDailyRemittancePage />} />,
                        loader: ({ params }) => query.ensureQueryData(getBusinessAccountQuery(params.id)),
                        handle: {
                          crumb: (businessAccount: BusinessAccountExtended) => (
                            <Link to={url.toBusinessAccountDailyRemittance(businessAccount.id)}>
                              {lang.get('common.menu.dailyRemittance')}
                            </Link>
                          ),
                        },
                      }, {
                        path: url.toBusinessAccountDailyTransactions(':id'),
                        element: <PrivateRoute component={<BusinessAccountDailyTransactionsPage />} />,
                        loader: ({ params }) => query.ensureQueryData(getBusinessAccountQuery(params.id)),
                        handle: {
                          crumb: (businessAccount: BusinessAccountExtended) => (
                            <Link to={url.toBusinessAccountDailyTransactions(businessAccount.id)}>
                              {lang.get('common.menu.dailyTransactions')}
                            </Link>
                          ),
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          }, {
            path: url.toBusinessApplications(),
            element: <PrivateRoute component={<BusinessApplicationsPage />} />,
            handle: {
              crumb: () => (
                <Link to={url.toBusinessApplications()}>
                  {lang.get('common.menu.businessApplications')}
                </Link>
              ),
            },
          }, {
            path: url.toTransactions(),
            handle: {
              crumb: () => (
                <Link to={url.toTransactions()}>
                  {lang.get('common.menu.transactions')}
                </Link>
              ),
            },
            children: [
              {
                index: true,
                element: <PrivateRoute component={<TransactionsPage />} />,
              }, {
                path: url.toBundles(),
                element: <PrivateRoute component={<BundlesPage />} />,
                handle: {
                  crumb: () => (
                    <Link to={url.toBundles()}>
                      {lang.get('common.menu.bundles')}
                    </Link>
                  ),
                },
              },
            ],
          }, {
            path: url.toReports(),
            handle: {
              crumb: () => <span>{lang.get('common.menu.reports')}</span>,
            },
            children: [
              {
                index: true,
                element: <NotFoundPage />,
              }, {
                path: url.toDailyRemittance(),
                element: <PrivateRoute component={<DailyRemittancePage />} />,
                handle: {
                  crumb: () => (
                    <Link to={url.toDailyRemittance()}>
                      {lang.get('common.menu.dailyRemittance')}
                    </Link>
                  ),
                },
              }, {
                path: url.toBusinessAccountsDailyBalance(),
                element: <PrivateRoute component={<BusinessAccountsDailyBalancePage />} />,
                handle: {
                  crumb: () => (
                    <Link to={url.toBusinessAccountsDailyBalance()}>
                      {lang.get('common.menu.businessAccountsDailyBalance')}
                    </Link>
                  ),
                },
              }, {
                path: url.toBusinessAccountsDailyRevenue(),
                element: <PrivateRoute component={<BusinessAccountsDailyRevenuePage />} />,
                handle: {
                  crumb: () => (
                    <Link to={url.toBusinessAccountsDailyRevenue()}>
                      {lang.get('common.menu.businessAccountsDailyRevenue')}
                    </Link>
                  ),
                },
              }, {
                path: url.toProvidersDailyBalance(),
                element: <PrivateRoute component={<ProvidersDailyBalancePage />} />,
                handle: {
                  crumb: () => (
                    <Link to={url.toProvidersDailyBalance()}>
                      {lang.get('common.menu.providersDailyBalance')}
                    </Link>
                  ),
                },
              }, {
                path: url.toProvidersDailySettlement(),
                element: <PrivateRoute component={<ProvidersDailySettlementPage />} />,
                handle: {
                  crumb: () => (
                    <Link to={url.toProvidersDailySettlement()}>
                      {lang.get('common.menu.providersDailySettlement')}
                    </Link>
                  ),
                },
              },
            ],
          }, {
            path: url.toSettings(),
            handle: {
              crumb: () => <span>{lang.get('common.menu.settings')}</span>,
            },
            children: [
              {
                index: true,
                element: <NotFoundPage />,
              }, {
                path: url.toAuditLogs(),
                element: <PrivateRoute component={<AuditLogsPage />} />,
                handle: {
                  crumb: () => (
                    <Link to={url.toAuditLogs()}>
                      {lang.get('common.menu.auditLogs')}
                    </Link>),
                },
              }, {
                path: url.toCostManagement(),
                element: <PrivateRoute component={<CostManagementPage />} />,
                handle: {
                  crumb: () => (
                    <Link to={url.toCostManagement()}>
                      {lang.get('common.menu.costManagement')}
                    </Link>),
                },
              }, {
                path: url.toCurrencies(),
                element: <PrivateRoute component={<CurrenciesPage />} />,
                handle: {
                  crumb: () => (
                    <Link to={url.toCurrencies()}>
                      {lang.get('common.menu.currencies')}
                    </Link>),
                },
              }, {
                path: url.toFeeManagement(),
                element: <PrivateRoute component={<FeeManagementPage />} />,
                handle: {
                  crumb: () => (
                    <Link to={url.toFeeManagement()}>
                      {lang.get('common.menu.feeManagement')}
                    </Link>),
                },
              }, {
                path: url.toUsers(),
                element: <PrivateRoute component={<UsersPage />} />,
                handle: {
                  crumb: () => (
                    <Link to={url.toUsers()}>
                      {lang.get('common.menu.users')}
                    </Link>),
                },
              },
            ],
          },
        ],
      },
    ],
  },
]);

const AppProvider = () => {
  const auth = useAuth();
  const preloader = usePreloader();

  useEffect(() => {
    preloader.toggle(!auth.initialized);
  }, [auth.initialized, preloader]);

  return auth.initialized && <RouterProvider router={router} />;
};

const App = {
  Provider: AppProvider,
};

export default App;
