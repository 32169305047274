import React, { FC } from 'react';
import { Select as BaseSelect } from 'antd';
import cx from 'classnames';

import { ChevronDown } from 'components/icons';
import { SelectProps } from 'types/components';

import styles from './styles.module.css';

import Ajax from './Ajax';

const Select: FC<SelectProps> = ({
  className,
  size = 'large',
  value,
  suffixIcon = <ChevronDown />,
  filterOption,
  filterSort,
  sort,
  ...props
}) => (
  <BaseSelect
    className={cx(styles.root, className)}
    size={size}
    value={value || undefined}
    suffixIcon={suffixIcon}
    filterOption={filterOption || ((input, option) => {
      let value = option?.title ?? '';

      if (typeof option?.label === 'string') {
        value = option?.label;
      }

      return value.trim().toLowerCase().includes(input.trim().toLowerCase());
    })}
    filterSort={filterSort || sort ? ((optionA, optionB) => {
      if (optionA.value === '' || optionB.value === '') {
        return 0; // preserve position of the "reset" option
      }

      let valueA = optionA.title ?? '';
      let valueB = optionB.title ?? '';

      if (typeof optionA.label === 'string' && typeof optionB.label === 'string') {
        valueA = optionA?.label;
        valueB = optionB?.label;
      }

      return valueA.trim().localeCompare(valueB.trim());
    }) : undefined}
    {...props}
  />
);

export default Object.assign(Select, { Ajax });
