import React, { FC, useState } from 'react';
import map from 'lodash/map';

import { useLang, useModal } from 'hooks';
import { useBusinessAccountMembersQuery } from 'hooks/queries';
import { Plus } from 'components/icons';
import { TableView } from 'components/layout';
import { Button, Table } from 'components/ui';
import { Uuid } from 'types/common';
import { TableColumns } from 'types/components';
import { BusinessAccount, BusinessAccountMember } from 'types/models';

import styles from './styles.module.css';

import AddModal from './AddModal';
import ViewModal from './ViewModal';

type MembersViewProps = {
  businessAccount: BusinessAccount;
};

const MembersView: FC<MembersViewProps> = ({ businessAccount }) => {
  const lang = useLang();
  const addModal = useModal();
  const viewModal = useModal();

  const [memberId, setMemberId] = useState<Uuid>();

  const membersQuery = useBusinessAccountMembersQuery(businessAccount.id);

  const handleAddClick = addModal.show;

  const handleViewClick = (member: BusinessAccountMember) => () => {
    setMemberId(member.id);

    viewModal.show();
  };

  const columns: TableColumns<BusinessAccountMember> = [
    {
      className: styles.table__email,
      key: 'email',
      title: lang.get('businessAccount.member.list.email'),
      render: (_, member) => <Table.Truncate width="middle">{member.email}</Table.Truncate>,
    }, {
      key: 'firstName',
      title: lang.get('businessAccount.member.list.firstName'),
      render: (_, member) => <Table.Truncate>{member.firstName || '-'}</Table.Truncate>,
    }, {
      key: 'lastName',
      title: lang.get('businessAccount.member.list.lastName'),
      render: (_, member) => <Table.Truncate>{member.lastName || '-'}</Table.Truncate>,
    }, {
      key: 'role',
      title: lang.get('businessAccount.member.list.roles'),
      render: (_, member) => map(member.roles, 'name').join(', ') || '-',
    },
  ];

  const member = membersQuery.data?.find((member) => member.id === memberId);

  return (
    <>

      <TableView
        title={lang.get('businessAccount.member.list.title')}
        actions={(
          <Button icon={<Plus />} onClick={handleAddClick}>
            {lang.get('businessAccount.member.list.add')}
          </Button>
        )}
      >
        <Table<BusinessAccountMember>
          columns={columns}
          dataSource={membersQuery.data ?? []}
          rowKey={(member) => member.id}
          loading={membersQuery.isFetching}
          clickable
          onRow={(member) => ({ onClick: handleViewClick(member) })}
        />
      </TableView>

      <AddModal
        businessAccountId={businessAccount.id}
        open={addModal.open}
        onClose={addModal.hide}
      />

      {member && (
        <ViewModal
          businessAccountId={businessAccount.id}
          member={member}
          open={viewModal.open}
          onClose={viewModal.hide}
        />
      )}

    </>
  );
};

export default MembersView;
