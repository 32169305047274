import React, { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { config, moment } from 'data';
import { formatter, url } from 'helpers';
import { reportService, transactionService } from 'services';
import { fetchPaginatedResponseFully } from 'services/helpers';
import { useLang, useQuery, useTable, useTableQuery } from 'hooks';
import { BusinessAccountIcon, TableView } from 'components/layout';
import { DateRangePicker, Form } from 'components/ui';
import { ExportColumns, TableColumns } from 'types/components';
import { BusinessAccount, ReportDailyTransactionItem } from 'types/models';
import { ReportDailyTransactionsParams } from 'types/services';
import { TransactionsViewFilters } from 'types/views';

import styles from './styles.module.css';

const EXPORT_FILE_NAME = 'report-daily-transactions';

type TableParams = {
  dates: [string, string];
};

const initialTableParams: TableParams = {
  dates: [
    moment().subtract(1, 'day').startOf('day').toISOString(),
    moment().subtract(1, 'day').endOf('day').toISOString(),
  ],
};

type DailyTransactionsViewProps = {
  businessAccount: BusinessAccount;
};

const DailyTransactionsView: FC<DailyTransactionsViewProps> = ({ businessAccount }) => {
  const lang = useLang();
  const navigate = useNavigate();
  const table = useTable<ReportDailyTransactionItem, TableParams>([config.BUSINESS_ACCOUNT_DAILY_TRANSACTIONS_QUERY_KEY, businessAccount.id], initialTableParams);

  const reportParams: ReportDailyTransactionsParams = {
    clientId: businessAccount.id,
    page: table.page,
    dateFrom: table.params.dates[0],
    dateTo: table.params.dates[1],
  };

  const reportQuery = useQuery({
    queryKey: [config.BUSINESS_ACCOUNT_DAILY_TRANSACTIONS_QUERY_KEY, reportParams],
    queryFn: () => reportService.getDailyTransactions(reportParams),
  });

  const handleViewClick = (item: ReportDailyTransactionItem) => () => {
    const filters: TransactionsViewFilters = {
      search: businessAccount.name,
      originCurrency: item.currency,
      status: transactionService.getTransactionAcceptedStatuses(),
      dates: [
        moment(item.date).startOf('day').toISOString(),
        moment(item.date).endOf('day').toISOString(),
      ],
    };

    navigate(url.toTransactions(undefined, { [config.FILTERS_QUERY_PARAM]: filters }));
  };

  useTableQuery(table, reportQuery);

  const formatItemDate = (item: ReportDailyTransactionItem) => formatter.formatDate(item.date);

  const formatItemCount = (item: ReportDailyTransactionItem) => formatter.formatNumber(item.totalTransactions, 0);

  const formatItemCurrency = (item: ReportDailyTransactionItem) => item.currency;

  const formatItemAmount = (item: ReportDailyTransactionItem) => formatter.formatNumber(item.totalAmount);

  const formatItemTransactionFees = (item: ReportDailyTransactionItem) => formatter.formatNumber(item.totalTransactionFees);

  const formatItemProviderFees = (item: ReportDailyTransactionItem) => formatter.formatNumber(item.totalProviderFees);

  const exportColumns: ExportColumns<ReportDailyTransactionItem> = useMemo(() => [
    {
      title: lang.get('businessAccount.dailyTransactions.date'),
      render: (item) => formatItemDate(item),
    }, {
      title: lang.get('businessAccount.dailyTransactions.count'),
      render: (item) => formatItemCount(item),
    }, {
      title: lang.get('businessAccount.dailyTransactions.currency'),
      render: (item) => formatItemCurrency(item),
    }, {
      title: lang.get('businessAccount.dailyTransactions.amount'),
      render: (item) => formatItemAmount(item),
    }, {
      title: lang.get('businessAccount.dailyTransactions.transactionFees'),
      render: (item) => formatItemTransactionFees(item),
    }, {
      title: lang.get('businessAccount.dailyTransactions.providerFees'),
      render: (item) => formatItemProviderFees(item),
    },
  ], [lang]);

  const tableColumns: TableColumns<ReportDailyTransactionItem> = [
    {
      className: styles.table__date,
      key: 'date',
      title: lang.get('businessAccount.dailyTransactions.date'),
      render: (_, item) => formatItemDate(item),
    }, {
      key: 'count',
      title: lang.get('businessAccount.dailyTransactions.count'),
      render: (_, item) => formatItemCount(item),
    }, {
      key: 'currency',
      title: lang.get('businessAccount.dailyTransactions.currency'),
      render: (_, item) => formatItemCurrency(item),
    }, {
      className: styles.table__amount,
      key: 'amount',
      title: lang.get('businessAccount.dailyTransactions.amount'),
      render: (_, item) => formatItemAmount(item),
    }, {
      className: styles.table__balance,
      key: 'transactionFees',
      title: lang.get('businessAccount.dailyTransactions.transactionFees'),
      render: (_, item) => formatItemTransactionFees(item),
    }, {
      className: styles.table__balance,
      key: 'providerFees',
      title: lang.get('businessAccount.dailyTransactions.providerFees'),
      render: (_, item) => formatItemProviderFees(item),
    },
  ];

  return (
    <TableView
      icon={<BusinessAccountIcon businessAccountType={businessAccount.type} size="large" />}
      title={lang.get('businessAccount.dailyTransactions.title', { name: businessAccount.name })}
      actions={(
        <TableView.ExportButton<ReportDailyTransactionItem>
          table={table}
          fileName={[
            EXPORT_FILE_NAME,
            businessAccount.name,
            moment(table.params.dates[0]).format(config.DATE_RAW_FORMAT),
            moment(table.params.dates[1]).format(config.DATE_RAW_FORMAT),
          ].join('-')}
          columns={exportColumns}
          fetchData={() => fetchPaginatedResponseFully(reportService.getDailyTransactions, reportParams)}
        />
      )}
    >

      <TableView.Filters<TableParams>
        initialValues={initialTableParams}
        values={table.params}
        inline
        onSubmit={table.setParams}
      >
        <Form.Item name="dates">
          <DateRangePicker maxDate={moment().subtract(1, 'day').endOf('day')} allowClear={false} />
        </Form.Item>
      </TableView.Filters>

      <TableView.Table<ReportDailyTransactionItem>
        table={table}
        columns={tableColumns}
        rowKey={(item) => item.date + item.currency}
        loading={reportQuery.isFetching}
        clickable
        onRow={(item) => ({ onClick: handleViewClick(item) })}
      />

    </TableView>
  );
};

export default DailyTransactionsView;
