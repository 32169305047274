import React from 'react';

import { useLang, useModal } from 'hooks';
import { useUserRolesQuery } from 'hooks/queries';
import { CardView } from 'components/layout';
import { Button } from 'components/ui';

import ListModal from './ListModal';

const RolesView = () => {
  const lang = useLang();
  const modal = useModal();

  const rolesQuery = useUserRolesQuery();

  return (
    <>

      <CardView
        title={lang.get('role.list.title')}
        caption={lang.choice('role.list.caption', rolesQuery.data?.total ?? 0)}
        actions={(
          <Button
            color="primary"
            variant="filled"
            loading={rolesQuery.isFetching}
            onClick={modal.show}
          >
            {lang.get('common.actions.edit')}
          </Button>
        )}
      />

      <ListModal
        open={modal.open}
        onClose={modal.hide}
      />

    </>
  );
};

export default RolesView;
