import map from 'lodash/map';

import { axios } from 'data';
import { Uuid } from 'types/common';

import {
  BusinessAccount,
  BusinessAccountBalance,
  BusinessAccountDetails,
  BusinessAccountExtended,
} from 'types/models';

import {
  BusinessAccountAddMemberParams,
  BusinessAccountMembersResponse,
  BusinessAccountRolesResponse,
  BusinessAccountsBalanceParams,
  BusinessAccountsParams,
  BusinessAccountsResponse,
  BusinessAccountUpdateDetailsParams,
  BusinessAccountUpdateMemberRolesParams,
  BusinessAccountVirtualAccountsResponse,
} from 'types/services';

import { preparePaginatableParams, preparePaginatedData } from './helpers';

const loadBusinessAccountsBalance = async <Type extends BusinessAccount>(items: Type[]) => {
  if (!items.length) {
    return items;
  }

  const balances = await getBusinessAccountsBalance(map(items, 'id'));

  return items.map<Type>((item) => ({
    ...item,
    balance: balances.find((balance) => balance.id === item.id),
  }));
};

const getBusinessAccounts = async (params: BusinessAccountsParams = {}) => {
  const response = await axios.get<BusinessAccountsResponse>('/core/admin/business-profile', {
    params: preparePaginatableParams(params),
  });

  const data = preparePaginatedData<BusinessAccount>(response.data);

  data.list = await loadBusinessAccountsBalance<BusinessAccount>(data.list);

  return data;
};

const getBusinessAccountsBalance = async (ids: Uuid[]) => {
  const params: BusinessAccountsBalanceParams = { clientIds: ids };
  const response = await axios.post<BusinessAccountBalance[]>('/payments/admin/client/balance', params);

  return response.data;
};

const getBusinessAccount = async (id: Uuid) => {
  const response = await axios.get<BusinessAccountExtended>(`/core/admin/business-profile/${id}`);
  const data = await loadBusinessAccountsBalance<BusinessAccountExtended>([response.data]);

  return data[0];
};

const activateBusinessAccount = async (id: Uuid) => {
  await axios.patch<never>(`/core/admin/business-profile/${id}/activate`);
};

const deactivateBusinessAccount = async (id: Uuid) => {
  await axios.patch<never>(`/core/admin/business-profile/${id}/deactivate`);
};

const verifyBusinessAccount = async (id: Uuid) => {
  await axios.put<never>(`/core/admin/business-profile/${id}/verified`);
};

const unverifyBusinessAccount = async (id: Uuid) => {
  await axios.put<never>(`/core/admin/business-profile/${id}/unverified`);
};

const enableBusinessAccountApi = async (id: Uuid) => {
  await axios.put<never>(`/core/admin/business-profile/${id}/enable-api`);
};

const disableBusinessAccountApi = async (id: Uuid) => {
  await axios.put<never>(`/core/admin/business-profile/${id}/disable-api`);
};

const resetBusinessAccountApiCredentials = async (id: Uuid) => {
  await axios.post<never>(`/payments/admin/client/${id}/reset-credentials`);
};

const getBusinessAccountDetails = async (id: Uuid) => {
  const response = await axios.get<BusinessAccountDetails>(`/payments/admin/client/${id}`);

  return response.data;
};

const updateBusinessAccountDetails = async (id: Uuid, params: BusinessAccountUpdateDetailsParams) => {
  await axios.patch<never>(`/payments/admin/client/${id}`, params);
};

const getBusinessAccountVirtualAccounts = async (id: Uuid) => {
  const response = await axios.get<BusinessAccountVirtualAccountsResponse>(`/payments/admin/client/${id}/virtual-accounts`);

  return response.data.list;
};

const getMembers = async (accountId: Uuid) => {
  const response = await axios.get<BusinessAccountMembersResponse>(`/core/admin/business-profile/${accountId}/members`);

  return response.data.list;
};

const addMember = async (accountId: Uuid, clientId: Uuid, params: BusinessAccountAddMemberParams) => {
  await axios.post<never>(`/core/admin/business-profile/${accountId}/member/${clientId}`, params);
};

const updateMemberRoles = async (accountId: Uuid, clientId: Uuid, params: BusinessAccountUpdateMemberRolesParams) => {
  await axios.put<never>(`/core/admin/business-profile/${accountId}/member/${clientId}/roles`, params);
};

const removeMember = async (accountId: Uuid, clientId: Uuid) => {
  await axios.delete<never>(`/core/admin/business-profile/${accountId}/member/${clientId}`);
};

const getRoles = async () => {
  const response = await axios.get<BusinessAccountRolesResponse>('/core/role/client');

  return response.data.list;
};

const businessAccountService = {
  getBusinessAccounts,
  getBusinessAccount,
  activateBusinessAccount,
  deactivateBusinessAccount,
  verifyBusinessAccount,
  unverifyBusinessAccount,
  enableBusinessAccountApi,
  disableBusinessAccountApi,
  resetBusinessAccountApiCredentials,
  getBusinessAccountDetails,
  updateBusinessAccountDetails,
  getBusinessAccountVirtualAccounts,
  getMembers,
  addMember,
  updateMemberRoles,
  removeMember,
  getRoles,
};

export default businessAccountService;
