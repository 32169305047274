import React, { FC } from 'react';

import { businessAccountService } from 'services';
import { Select } from 'components/ui';
import { SelectProps } from 'types/components';

type BusinessAccountSelectProps = Omit<SelectProps, 'onSearch'>;

const BusinessAccountSelect: FC<BusinessAccountSelectProps> = (props) => {
  const handleSearch = async (query: string) => {
    const { list } = await businessAccountService.getBusinessAccounts({ search: query || undefined });

    return list.map((businessAccount) => ({
      value: businessAccount.id,
      label: businessAccount.name,
    }));
  };

  return (
    <Select.Ajax onSearch={handleSearch} {...props} />
  );
};

export default BusinessAccountSelect;
